import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    IconButton,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import SubmitButtonImport from "../submit-buttons/submit-button-import";
import { useFilePicker } from 'use-file-picker';
import TextFieldComponent from "../../text-field-component";
import { AttachFile } from "@mui/icons-material";

export interface DeleteProps {
    showDialog: boolean;
    dialogTitle: string;
    dialogInfo: string | undefined;
    onClickSubmit: (value: string) => void;
    onClickDelete: () => void;
}

const ImportDataDialog = ({
    showDialog,
    onClickSubmit,
    onClickDelete,
    dialogInfo,
    dialogTitle
}: DeleteProps) => {
    const theme: Theme = useTheme();
    const { openFilePicker, filesContent } = useFilePicker({
        accept: ['.txt', '.csv','.json'],
    });
    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                {dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        {dialogInfo}
                        <div className="single-view">
                            <div className="flex flex-row single-view-person__all-container">
                                <div className="flex flex-column single-view-person__data-container">
                                    <div className="flex flex-row single-view__form-row-width">
                                        <TextFieldComponent
                                            disabled
                                            error={false}
                                            helperText=""
                                            id="file"
                                            label="Dateiname"
                                            required
                                            type="text"
                                            value={filesContent && filesContent.length > 0 ? filesContent[0].name : ""}
                                            endButton={
                                                <Tooltip title="Datei auswählen">
                                                    <IconButton edge="end" color="primary" onClick={openFilePicker}>
                                                        <AttachFile></AttachFile>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonImport
                    importText="Importieren"
                    onClickDelete={onClickDelete}
                    onClickImport={onClickSubmit}
                    value={filesContent && filesContent.length > 0 ? filesContent[0].content : ""}
                />
            </DialogActions>
        </Dialog >
    );
};

export default ImportDataDialog;
