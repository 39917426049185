import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { UserAPI, UserSort } from "../../../../models/user";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import {
    deleteUser,
    fetchUsers,
} from "../../../../shared/requests/user.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { useNavigate } from 'react-router-dom';
import ConfirmCancelDialog from '../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog';
import { clearHistory } from "../../../../models/history";
import { formatActiveString, formatTimestampString } from "../../../../helper/formatter";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { } from "../../../../redux/auth.reducer";
function ListUsers() {
    const [sortBy] = useState<Sort<UserSort>>({
        key: "lastname",
        direction: SortingDirection.Descending,
    });

    const [users, setUsers] = useState<UserAPI[]>([]);
    const [externalModified, setExternalModified] = useState<boolean>(false);
    const navigate = useNavigate();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [entityName, setEntityName] = useState<string>("");
    const [clickedId, setClickedId] = useState<string>("");
    const isInitRender = useRef(true);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: UserAPI) => {
        navigate(`edit/${entity.id}`);
    }
    const onDeleteClick = (entity: UserAPI) => {
        setEntityName(entity.lastname + ", " + entity.firstname)
        setClickedId(entity.id)
        setShowDeleteDialog(true)
    }
    const onDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const onDeleteSubmit = () => {
        deleteUser(clickedId ?? "").then(() => {
            setShowDeleteDialog(false)
            setExternalModified(true)
        });
    }

    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchUsers().then((response) => {
                setUsers(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<UserAPI, keyof UserAPI>[] = [
        {
            key: "lastname",
            header: "Nachname",
        },
        {
            key: "firstname",
            header: "Vorname",
        },
        {
            key: "email",
            header: "Email-Adresse",
        },
        {
            key: "activeString",
            header: "Kontostatus",
        },
        {
            key: "institutionString",
            header: "Institution",
        },
        {
            key: "latestLoginString",
            header: "Letzter Login",
        },
    ];

    const hasEditPermission = () => {
        if (user) {
            return (user.permissions.includes("user:edit") || user.permissions.includes("user_own_institution:edit"));
        } else {
            return false;
        }
    }
    const hasGetPermission = () => {
        if (user) {
            return (user.permissions.includes("user:get") || user.permissions.includes("user_own_institution:get"));
        } else {
            return false;
        }
    }
    return (
        <PageLayout title={"Übersicht Benutzer"}>
            <ConfirmCancelDialog
                showDialog={showDeleteDialog}
                dialogTitle={"Löschen eines Benutzer"}
                dialogInfo={`Soll der Benutzer { ${entityName} } wirklich gelöscht werden?`}
                onClickCancel={onDeleteCancel}
                onClickSubmit={onDeleteSubmit}
            />
            <CrudTable
                callOnAddClick={onAddClick}
                callOnEditClick={onEditClick}
                callOnDeleteClick={onDeleteClick}
                externalModified={externalModified}
                setExternalModified={setExternalModified}
                tableData={users}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchUsers}
                getPermission={hasGetPermission}
                editPermission={hasEditPermission}
                dataTypeName="Benutzer"
                dataFormatter={(element: UserAPI) => {
                    element.activeString = formatActiveString(element.active)
                    element.institutionString = element.institution.name;
                    element.latestLoginString = formatTimestampString(element.latestLogin)
                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ListUsers;
