import { resourceAxiosInstance } from "../../config/axios.config";
import { DateAPI } from "../../models/historization";
import { PersonConnectionAPI, PersonConnectionAPIRequest } from "../../models/person-connection";
import { ProcessAPI } from "../../models/user";

export const updatePersonConnection = (personConnection: Partial<PersonConnectionAPIRequest>) =>
    resourceAxiosInstance.patch<PersonConnectionAPI>(
        `/person-connection/${personConnection.id}`,
        personConnection,
        {
            cache: false,
        }
    );


export const makePersonConnectionInvalid = (personConnectionId: string | undefined, value: DateAPI) =>
    resourceAxiosInstance.put(`/person-connection/${personConnectionId}`,
        value,
        {
            cache: false,
        });

export const importPersonConnection = (personConnectionId: string | undefined, historyNumber: string) =>
    resourceAxiosInstance.put(`/person-connection/${personConnectionId}/history/${historyNumber}/import`,
        {
            cache: false,
        });

export const effectivePersonConnection = (personConnectionId: string | undefined, historyNumber: string) =>
    resourceAxiosInstance.put(`/person-connection/${personConnectionId}/history/${historyNumber}/effective`,
        {
            cache: false,
        });

export const fetchPersonConnection = (personConnectionId: string) =>
    resourceAxiosInstance.get<PersonConnectionAPI>(`/person-connection/${personConnectionId}`, {
        cache: false,
    });

export const fetchHistoryForPersonConnection = (personConnectionId: string) =>
    resourceAxiosInstance.get<PersonConnectionAPI[]>(`/person-connection/${personConnectionId}/history`, {
        cache: false,
    });

export const fetchSingleHistoryForPersonConnection = (personConnectionId: string, historyNumber: string) =>
    resourceAxiosInstance.get<PersonConnectionAPI>(`/person-connection/${personConnectionId}/history/${historyNumber}`, {
        cache: false,
    });

export const deletePersonConnection = (personConnectionId: string, historyNumber: string) =>
    resourceAxiosInstance.delete<PersonConnectionAPI>(`/person-connection/${personConnectionId}/history/${historyNumber}`, {
        cache: false,
    });

export const createPersonConnection = (personConnection: Partial<PersonConnectionAPIRequest>) =>
    resourceAxiosInstance.post<PersonConnectionAPI>(`/person-connection`, personConnection, {
        cache: false,
    });

export const fetchProcessAndCheckAccessabilityForPersonConnection = (personConnectionId: string, processId: string) =>
    resourceAxiosInstance.get<ProcessAPI>(`/person-connection/${personConnectionId}/process/${processId}`, {
        cache: false,
    });
