import { useEffect } from 'react';
import { Alert, Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StatusSelect, getStatusValues } from '../../../../models/historization';
import SubmitButtonsHistory from '../../../../shared/components/standard-actions/submit-buttons/submit-button-history';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { CaringPersonAPI, CaringPersonSelect } from '../../../../models/caring-person';
import { createPersonConnection, fetchPersonConnection, fetchHistoryForPersonConnection, fetchSingleHistoryForPersonConnection, updatePersonConnection } from '../../../../shared/requests/calculation-basis.requests';
import { PersonInNeedOfCareAPI, PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { fetchHistoryForPersonInNeedOfCare, fetchPersonsInNeedOfCare } from '../../../../shared/requests/person-in-need-of-care.requests';
import { fetchCaringPersons, fetchHistoryForCaringPerson } from '../../../../shared/requests/caring-person.requests';
import { } from "../../../../redux/auth.reducer";
import AccordionComponent from '../../../../shared/components/accordion-component';
import { endingDateSameOrAfterEffectiveDate } from '../../../../helper/validation';
import { PersonConnectionAPI, PersonConnectionAPIRequest } from '../../../../models/person-connection';
import PersonConnectionForm from '../form/person-connection-form';
import CalculationBasisForm from '../form/calculation-basis-form';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI } from '../../../../models/calculation-basis';
import InputErrorDialog from '../../../../shared/components/standard-actions/dialogs/input-error-dialog';
import { cancelProcess, saveProcess } from '../../../../shared/requests/user.requests';
import { addToHistory, clearHistory, getHistory, HistoryEntry, HistoryTimelineItemModel, updateHistoryUntilIndex } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';
import { formatTimestampString, formatTimeStampUserString } from '../../../../helper/formatter';
import SubmitButtonsShow from '../../../../shared/components/standard-actions/submit-buttons/submit-button-show';
import { NursingTimeAPI } from '../../../../models/nursing-time';
import { NursingSupportMoneyAPI } from '../../../../models/nursing-support-money';
import { InstitutionSelect } from '../../../../models/institution';
import { fetchInsurances } from '../../../../shared/requests/institution.requests';

function PersonConnection() {
    //Select Listen
    const [historyData, setHistoryData] = useState<PersonConnectionAPI[]>([]);
    const [statusValues, setStatusValues] = useState<StatusSelect[]>([]);
    const [caringPersonValues, setCaringPersonValues] = useState<CaringPersonSelect[]>([]);
    const [personInNeedOfCareValues, setPersonInNeedOfCareValues] = useState<PersonInNeedOfCareSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    //Berechnungsgrundlage extra Daten
    const [effectiveDateRV, setEffectiveDateRV] = useState<string>("");
    const [endingDateRV, setEndingDateRV] = useState<string | null>("");
    const [amountRV, setAmountRV] = useState<number>(0.0);
    const [amountFlagRV, setAmountFlagRV] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [effectiveDateAV, setEffectiveDateAV] = useState<string>("");
    const [endingDateAV, setEndingDateAV] = useState<string | null>("");
    const [amountAV, setAmountAV] = useState<number>(0.0);
    const [amountFlagAV, setAmountFlagAV] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [effectiveDateNursingTime, setEffectiveDateNursingTime] = useState<string>("");
    const [endingDateNursingTime, setEndingDateNursingTime] = useState<string>("");
    const [healthInsuranceNursingTime, setHealthInsuranceNursingTime] = useState<InstitutionSelect | null>(null);
    const [nursingInsuranceNursingTime, setNursingInsuranceNursingTime] = useState<InstitutionSelect | null>(null);
    const [amountHealthInsuranceNursingTime, setAmountHealthInsuranceNursingTime] = useState<number>(0.0);
    const [amountNursingInsuranceNursingTime, setAmountNursingInsuranceNursingTime] = useState<number>(0.0);
    const [percentageHealthInsuranceNursingTime, setPercentageHealthInsuranceNursingTime] = useState<number>(0.0);
    const [percentageNursingInsuranceNursingTime, setPercentageNursingInsuranceNursingTime] = useState<number>(0.0);
    const [effectiveDatePUG, setEffectiveDatePUG] = useState<string>("");
    const [endingDatePUG, setEndingDatePUG] = useState<string>("");
    const [healthInsurancePUG, setHealthInsurancePUG] = useState<InstitutionSelect | null>(null);
    const [amountPUG, setAmountPUG] = useState<number>(0.0);
    const [percentagePUG, setPercentagePUG] = useState<number>(0.0);
    const [amountHealthInsurancePUG, setAmountHealthInsurancePUG] = useState<number>(0.0);
    const [percentageHealthInsurancePUG, setPercentageHealthInsurancePUG] = useState<number>(0.0);
    const [amountHealthInsuranceBonusPUG, setAmountHealthInsuranceBonusPUG] = useState<number>(0.0);
    const [percentageHealthInsuranceBonusPUG, setPercentageHealthInsuranceBonusPUG] = useState<number>(0.0);
    const [amountPensionInsurancePUG, setAmountPensionInsurancePUG] = useState<number>(0.0);
    const [percentagePensionInsurancePUG, setPercentagePensionInsurancePUG] = useState<number>(0.0);
    const [amountUnemploymentInsurancePUG, setAmountUnemploymentInsurancePUG] = useState<number>(0.0);
    const [percentageUnemploymentInsurancePUG, setPercentageUnemploymentInsurancePUG] = useState<number>(0.0);
    const [caringPerson, setCaringPerson] = useState<CaringPersonSelect | null>(null);
    const [personInNeedOfCare, setPersonInNeedOfCare] = useState<PersonInNeedOfCareSelect | null>(null);
    const [note, setNote] = useState<string | null>("");
    const [calculationBasisListRV, setCalculationBasisListRV] = useState<CalculationBasisAPI[]>([]);
    const [calculationBasisListAV, setCalculationBasisListAV] = useState<CalculationBasisAPI[]>([]);
    const [nursingTimeList, setNursingTimeList] = useState<NursingTimeAPI[]>([]);
    const [nursingSupportMoneyList, setNursingSupportMoneyList] = useState<NursingSupportMoneyAPI[]>([]);
    const [aidLevelPercentagePersonInNeedOfCareZero, setAidLevelPercentagePersonInNeedOfCareZero] = useState<boolean>(false);
    const [earliestEffectiveDatePersonInNeedOfCare, setEarliestEffectiveDatePersonInNeedOfCare] = useState<string>("");
    const [earliestEffectiveDateCaringPerson, setEarliestEffectiveDateCaringPerson] = useState<string>("");

    //Systemdaten
    const [isShowMode, setShowMode] = useState<boolean>(false);
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const { id, history, processId } = useParams();
    const [showInputErrorDialog, setShowInputErrorDialog] = useState<boolean>(false);
    const [personInNeedOfCareDisabled, setPersonInNeedOfCareDisabled] = useState<boolean>(false);
    const [caringPersonDisabled, setCaringPersonDisabled] = useState<boolean>(false);
    const [pathToLastPersonOverview, setPathToLastPerson] = useState<string>("");
    const [leavePath, setLeavePath] = useState<string>("/");
    const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number>(-1);
    const errorConnection: boolean = caringPerson === null || personInNeedOfCare === null
    const errorCalculationBasisRV: boolean = effectiveDateRV === "" || endingDateRV === "" || !endingDateSameOrAfterEffectiveDate(endingDateRV, effectiveDateRV) || amountRV < 0 || amountFlagRV === null || effectiveDateRV < earliestEffectiveDateCaringPerson || effectiveDateRV < earliestEffectiveDatePersonInNeedOfCare;
    const errorCalculationBasisAV: boolean = effectiveDateAV === "" || endingDateAV === "" || !endingDateSameOrAfterEffectiveDate(endingDateAV, effectiveDateAV) || amountAV < 0 || amountFlagAV === null || effectiveDateAV < earliestEffectiveDateCaringPerson || effectiveDateAV < earliestEffectiveDatePersonInNeedOfCare;
    const formErrorNursingTime: boolean = effectiveDateNursingTime === "" || endingDateNursingTime === "" || !endingDateSameOrAfterEffectiveDate(endingDateNursingTime, effectiveDateNursingTime) || amountHealthInsuranceNursingTime < 0 || amountNursingInsuranceNursingTime < 0 ||
        percentageHealthInsuranceNursingTime < 0 || percentageHealthInsuranceNursingTime > 100 || percentageNursingInsuranceNursingTime < 0 || percentageNursingInsuranceNursingTime > 100 ||
        effectiveDateNursingTime < earliestEffectiveDateCaringPerson || effectiveDateNursingTime < earliestEffectiveDatePersonInNeedOfCare;
    const formErrorNursingSupportMoney: boolean = effectiveDatePUG === "" || endingDatePUG === "" || !endingDateSameOrAfterEffectiveDate(endingDatePUG, effectiveDatePUG) || amountHealthInsurancePUG < 0 || amountPUG < 0 || amountHealthInsuranceBonusPUG < 0 || amountPensionInsurancePUG < 0 || amountUnemploymentInsurancePUG < 0 ||
        percentagePUG < 0 || percentagePUG > 100 || percentageHealthInsurancePUG < 0 || percentageHealthInsurancePUG > 100 || percentageHealthInsuranceBonusPUG < 0 || percentageHealthInsuranceBonusPUG > 100 || percentagePensionInsurancePUG < 0 || percentagePensionInsurancePUG > 100 || percentageUnemploymentInsurancePUG < 0 || percentageUnemploymentInsurancePUG > 100 ||
        effectiveDatePUG < earliestEffectiveDateCaringPerson || effectiveDatePUG < earliestEffectiveDatePersonInNeedOfCare;
    const errorInput: boolean = errorConnection

    const navigate = useNavigate();

    const goBackToHome = () => {
        navigate(`/`);
    }

    const goBackToLastPerson = () => {
        if (pathToLastPersonOverview !== null && pathToLastPersonOverview !== "") {
            navigate(pathToLastPersonOverview);
        } else {
            navigate("/")
        }
    }

    useEffect(() => {
        const showMode: boolean = window.location.pathname.includes("/show/")
        setShowMode(showMode)
        setStatusValues(getStatusValues());
        let setPersonInNeedOfCareToId: string | null = null;
        let setCaringPersonToId: string | null = null;
        if (id) {
            setPersonInNeedOfCareDisabled(true)
            setCaringPersonDisabled(true)
            if (!history) {
                fetchSinglePersonAPI(id, showMode);
            } else {
                fetchSinglePersonHistoryAPI(id, history, showMode);
            }
        } else {
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                const history = addToHistory({
                    type: "Berechnungsgrundlage",
                    name: `Neue Berechnungsgrundlage`,
                    url: window.location.pathname,
                    id: "-1"
                });
                history.forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url,
                        id: element.id
                    })
                }))
                setHistoryChronoItems(historyData);
                setCurrentRendered(true);
                if (history[history.length - 2].type === "Bedürftige/r") {
                    setPersonInNeedOfCareToId = history[history.length - 2].id;
                    setPathToLastPerson("/person-in-need-of-care");
                } else if (history[history.length - 2].type === "Pflegeperson") {
                    setCaringPersonToId = history[history.length - 2].id;
                    setPathToLastPerson("/caring-person");
                }
            }
        }
        fetchPersonsInNeedOfCare(true).then((response) => {
            let personsInNeedOfCare = response.data;
            let personsPinocSelect: CaringPersonSelect[] = [];
            personsInNeedOfCare.forEach((person) => {
                personsPinocSelect.push({
                    id: String(person.id),
                    name: person.lastName + ", " + person.firstName,
                    realValue: +person.id,
                    genderId: person.gender
                })
            })
            if (setPersonInNeedOfCareToId !== null) {
                const person = personsPinocSelect.find(({ id }) => String(id) === String(setPersonInNeedOfCareToId))
                if (person) {
                    setPersonInNeedOfCare(person);
                    setPersonInNeedOfCareDisabled(true)
                    setEarliestEffectiveDateForPersonInNeedOfCareWithID(person.id)
                }
            }
            setPersonInNeedOfCareValues(personsPinocSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }))
        });
        fetchCaringPersons(true).then((response) => {
            let caringPersons = response.data;
            let personsCPSelect: CaringPersonSelect[] = [];
            caringPersons.forEach((person) => {
                personsCPSelect.push({
                    id: String(person.id),
                    name: person.lastName + ", " + person.firstName,
                    realValue: +person.id,
                    genderId: person.gender
                })
            })
            if (setCaringPersonToId !== null) {
                const person = personsCPSelect.find(({ id }) => String(id) === String(setCaringPersonToId))
                if (person) {
                    setCaringPerson(person);
                    setCaringPersonDisabled(true)
                    setEarliestEffectiveDateForCaringPersonWithID(person.id)
                }
            }
            setCaringPersonValues(personsCPSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }))
        });
        fetchInsurancesWithMaybeOffset();
        setEffectiveDateRV("")
        setEffectiveDateAV("")
        setEndingDateRV("")
        setEndingDateAV("")

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history, processId]);

    const getUserTitle = (): string => {
        const createUserTitle = "Berechnungsgrundlage erstellen";
        const editUserTitle = "Berechnungsgrundlage bearbeiten";
        const showTitle = "Berechnungsgrundlage anzeigen";
        if (isShowMode) {
            return showTitle
        }
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const fetchSinglePersonAPI = (id: string, showMode: boolean) => {
        fetchPersonConnection(id).then((response) => {
            workWithFetchedPersonConnectionData(response.data, showMode)
        });
    }

    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string, showMode: boolean) => {
        fetchSingleHistoryForPersonConnection(id, historyNumber).then((response) => {
            workWithFetchedPersonConnectionData(response.data, showMode)
        });
    }

    const workWithFetchedPersonConnectionData = (person: PersonConnectionAPI | PersonConnectionAPI, showMode: boolean) => {
        fillPersonConnectionFields(person)
        fetchHistoryData(person.id, showMode)
        if (!isCurrentRendered) {
            const historyData: HistoryTimelineItemModel[] = [];
            const history = addToHistory({
                type: "Berechnungsgrundlage",
                name: `${person.personInNeedOfCare.person.lastname}, ${person.personInNeedOfCare.person.firstname} | ${person.caringPerson.person.lastname}, ${person.caringPerson.person.firstname}`,
                url: window.location.pathname,
                id: person.id
            })
            history.forEach((element => {
                historyData.push({
                    type: element.type,
                    title: element.name,
                    url: element.url,
                    id: element.id
                })
            }))
            setHistoryChronoItems(historyData);
            if (history[history.length - 2].type === "Bedürftige/r") {
                setPathToLastPerson("/person-in-need-of-care");
            } else if (history[history.length - 2].type === "Pflegeperson") {
                setPathToLastPerson("/caring-person");
            }
            setCurrentRendered(true);

        }
    }

    const fillPersonConnectionFields = (person: PersonConnectionAPI | PersonConnectionAPI) => {
        const cp = {
            id: person.caringPerson.id,
            name: person.caringPerson.person.lastname + ", " + person.caringPerson.person.firstname,
            realValue: +person.caringPerson.id,
            genderId: person.caringPerson.person.gender
        }
        const pinoc = {
            id: person.personInNeedOfCare.id,
            name: person.personInNeedOfCare.person.lastname + ", " + person.personInNeedOfCare.person.firstname,
            realValue: +person.personInNeedOfCare.id,
            genderId: person.personInNeedOfCare.person.gender
        };
        setCaringPerson(cp);
        setPersonInNeedOfCare(pinoc);
        setNote(person.note)
        if (pinoc != null) {
            setEarliestEffectiveDateForPersonInNeedOfCareWithID(pinoc.id)
        }
        if (cp != null) {
            setEarliestEffectiveDateForCaringPersonWithID(cp.id)
        }
        setCalculationBasisListRV(person.calculationBasisListRV.sort((a, b) => a.from > b.from ? -1 : 1))
        setCalculationBasisListAV(person.calculationBasisListAV.sort((a, b) => a.from > b.from ? -1 : 1))
        setNursingSupportMoneyList(person.nursingSupportMoneyList.sort((a, b) => a.from > b.from ? -1 : 1))
        setNursingTimeList(person.nursingTimeList.sort((a, b) => a.from > b.from ? -1 : 1))

    }

    const fetchHistoryData = (id: string, showMode: boolean) => {
        fetchHistoryForPersonConnection(id).then((response2) => {
            const data: PersonConnectionAPI[] = []
            response2.data.forEach(element => {
                if (element.status === "STATUS_FLOATING" && !history && !showMode) {
                    const newHistory: HistoryEntry[] = getHistory();
                    newHistory.pop();
                    localStorage.history = JSON.stringify(newHistory);
                    navigate(`/calculation-basis/edit/${id}/${element.historyNumber}/${processId}`)

                }
                data.push(element);
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
            })
            setHistoryData(data);
        });
    }

    const setPersonInNeedOfCareValue = (value: string) => {
        const person: PersonInNeedOfCareSelect | null = personInNeedOfCareValues.find(({ name }) => name === value) || null
        setPersonInNeedOfCare(person)
        if (person != null) {
            setEarliestEffectiveDateForPersonInNeedOfCareWithID(person.id)
        }
    }

    const setCaringPersonValue = (value: string) => {
        const person: CaringPersonSelect | null = caringPersonValues.find(({ name }) => name === value) || null
        setCaringPerson(person)
        if (person != null) {
            setEarliestEffectiveDateForCaringPersonWithID(person.id)
        }
    }

    const fetchInsurancesWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            setInsuranceValues(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
    }

    const setEarliestEffectiveDateForCaringPersonWithID = (id: string) => {
        fetchHistoryForCaringPerson(id).then((response2) => {
            const response: CaringPersonAPI[] = response2.data.filter(a => a.deleteTransaction === null).sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                return 0;
            })
            if (response.length >= 1) {
                setEarliestEffectiveDateCaringPerson(response[0].effectiveDate)
            }
        })
    }

    const setEarliestEffectiveDateForPersonInNeedOfCareWithID = (id: string) => {
        fetchHistoryForPersonInNeedOfCare(id).then((response2) => {
            const response: PersonInNeedOfCareAPI[] = response2.data.filter(a => a.deleteTransaction === null && a.validity === "VALIDITY_VALID").sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                return 0;
            })
            if (response.length >= 1) {
                setEarliestEffectiveDatePersonInNeedOfCare(response[0].effectiveDate)
                response.forEach(element => {
                    if (element.aidLevel === null || element.aidLevel.percentage === 0) {
                        setAidLevelPercentagePersonInNeedOfCareZero(true)
                    }
                })

            }
        })
    }



    const savePersonConnection = (status: StatusSelect | null, processSave: boolean, historyClear: boolean, route?: string) => {
        if (!isShowMode) {
            const caringPersonId = caringPerson ? caringPerson.id : "-1";
            const personInNeedOfCareId = personInNeedOfCare ? personInNeedOfCare.id : "-1";
            const stat = status ? status.id : "";
            const processIdentifier = processId ? processId : "-1"
            const personConnection: PersonConnectionAPIRequest = {
                id: id ? id : "-1",
                status: stat,
                caringPersonId,
                personInNeedOfCareId,
                calculationBasisListRV: calculationBasisListRV,
                calculationBasisListAV: calculationBasisListAV,
                nursingSupportMoneyList: nursingSupportMoneyList,
                nursingTimeList: nursingTimeList,
                processIdentifier,
                note

            };
            setSavePushed(true);
            if (!errorInput && status !== null) {
                if (id) {
                    updatePersonConnection(personConnection).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            if (route) {
                                navigate(route);
                            }
                            else {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            }
                        }
                    }).catch(console.error);
                } else {
                    createPersonConnection(personConnection).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                        }
                    }).catch(console.error);
                }
            }
        }
    };

    const maybeClearHistoryAndNavigateRoute = (historyClear: boolean, person: PersonConnectionAPI, route?: string) => {
        if (historyClear) {
            clearHistory()
        }
        const history = getHistory();
        if (history.length !== 0 && history[history.length - 1].id === "-1") {
            updateHistoryUntilIndex(history.length - 1);
            const url = processId ? `/calculation-basis/edit/${person.id}/${processId}/process` : `/calculation-basis/edit/${person.id}`
            addToHistory({
                type: "Berechnungsgrundlage",
                name: `${person.personInNeedOfCare.person.lastname}, ${person.personInNeedOfCare.person.firstname} | ${person.caringPerson.person.lastname}, ${person.caringPerson.person.firstname}`,
                url: url,
                id: person.id
            })
        }
        if (route) {
            navigate(route);
        }
        else {
            goBackToHome()
        }
    }

    const onClickHistoryShow = (person: PersonConnectionAPI) => {
        if (isShowMode) {
            navigate(`/calculation-basis/show/${id}/history/${person.historyNumber}`)
        } else {
            onClickSaveAndOpenPath(`/calculation-basis/edit/${id}/${processId}/process/show/${person.historyNumber}`)
        }
    }

    const onClickCaringPersonEdit = () => {
        if (caringPerson) {
            if (isShowMode) {
                navigate(`/caring-person/show/${caringPerson.id}`)
            } else {
                if (processId) {
                    onClickSaveAndOpenPath(`/caring-person/edit/${caringPerson.id}/${processId}`)
                } else {
                    onClickSaveAndOpenPath(`/caring-person/edit/${caringPerson.id}`)
                }
            }
        }
    }

    const onClickPersonInNeedOfCareEdit = () => {
        if (personInNeedOfCare) {
            if (isShowMode) {
                navigate(`/person-in-need-of-care/show/${personInNeedOfCare.id}`)
            } else {
                if (processId) {
                    onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personInNeedOfCare.id}/${processId}`)
                } else {
                    onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personInNeedOfCare.id}`)
                }
            }
        }
    }

    const onClickSaveAndOpenPath = (path: string) => {
        if (!isShowMode) {
            if (!errorInput) {
                savePersonConnection(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false, path);
            } else {
                setLeavePath(path);
                setShowInputErrorDialog(true);
            }
        }
    }

    const onSave = () => {
        if (!isShowMode) {
            let path: string | undefined = undefined;
            let clearHistory: boolean = false;
            if (historyChronoItems.length > 1) {
                if (historyChronoItems[historyChronoItems.length - 2].type === "Pflegeperson") {
                    path = `/caring-person/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Bedürftige/r") {
                    path = `/person-in-need-of-care/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Berechnungsgrundlage") {
                    path = `/calculation-basis/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
            }
            savePersonConnection(statusValues.find(({ id }) => id === "STATUS_EFFECTIVE") || null, true, clearHistory, path)
        }
    }

    const onEditCancel = () => {
        if (!isShowMode) {
            if (processId) {
                cancelProcess(processId).then(() => { goBackToLastPerson() });
            } else {
                goBackToLastPerson()
            }
        }
    }

    const actions = (
        <SubmitButtonsHistory
            processId={processId}
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={onSave}
            onClickCancel={onEditCancel}
            onClickPause={() => {
                savePersonConnection(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false);
            }}
        />
    )

    const onClickBack = () => {
        if (historyChronoItems.length > 1) {
            const path: string = historyChronoItems[historyChronoItems.length - 2].url
            updateHistoryUntilIndex(historyChronoItems.length - 2)
            navigate(path)
        } else {
            goBackToHome();
        }
    }

    const actionsShow = (
        <SubmitButtonsShow
            onClickBack={onClickBack}
        />
    );

    const onLeaveSiteCancel = () => {
        const historyData: HistoryTimelineItemModel[] = [];
        getHistory().forEach(element => {
            historyData.push({
                type: element.type,
                title: element.name,
                url: element.url,
                id: element.id
            })
        })
        setHistoryChronoItems(historyData)
        setShowInputErrorDialog(false);
    };

    const onLeaveSiteSubmit = () => {
        setShowInputErrorDialog(false);
        if (selectedHistoryIndex >= 0) {
            updateHistoryUntilIndex(selectedHistoryIndex)
        }
        navigate(leavePath);
    };

    const columns: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "createDescriptionString",
            header: "Beschreibung Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
        {
            key: "deleteDescriptionString",
            header: "Beschreibung Löschung",
        },
    ];

    const [sortBy] = useState<Sort<CaringPersonAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Ascending,
    });

    return (
        <PageLayout title={getUserTitle()} actions={isShowMode ? actionsShow : actions}>
            {aidLevelPercentagePersonInNeedOfCareZero === true && <Alert severity="error">Der ausgewählte Bedürftige hat eine Beihilfestufe von 0% gesetzt</Alert>}
            <InputErrorDialog
                showDialog={showInputErrorDialog}
                dialogTitle="Berechnungsgrundlageneintrag löschen?"
                dialogInfo={`Die/Der Bedürftige/r kann nicht gespeichert werden, da das Formular nicht richtig ausgefüllt wurde. Soll die Seite trotzdem verlassen werden?`}
                onClickCancel={onLeaveSiteCancel}
                onClickSubmit={() => onLeaveSiteSubmit()}
            />
            <div className="single-view">
                <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                    saveAndOpenPath={isShowMode ? undefined : onClickSaveAndOpenPath}
                    errorInput={errorInput}
                    setShowInputErrorDialog={setShowInputErrorDialog}
                    setLeavePath={setLeavePath}
                    setSelectedIndex={setSelectedHistoryIndex}
                    setHistoryChronoItems={setHistoryChronoItems}
                />

                <PersonConnectionForm
                    caringPerson={caringPerson}
                    caringPersonValues={caringPersonValues}
                    disabled={isShowMode}
                    personInNeedOfCareDisabled={personInNeedOfCareDisabled}
                    caringPersonDisabled={caringPersonDisabled}
                    personInNeedOfCare={personInNeedOfCare}
                    personInNeedOfCareValues={personInNeedOfCareValues}
                    savePushed={savePushed}
                    setCaringPersonValue={setCaringPersonValue}
                    setPersonInNeedOfCareValue={setPersonInNeedOfCareValue}
                    earliestEffectiveDateCaringPerson={earliestEffectiveDateCaringPerson}
                    earliestEffectiveDatePersonInNeedOfCare={earliestEffectiveDatePersonInNeedOfCare}
                    setEarliestEffectiveDateCaringPerson={setEarliestEffectiveDateCaringPerson}
                    setEarliestEffectiveDatePersonInNeedOfCare={setEarliestEffectiveDatePersonInNeedOfCare}
                    note={note}
                    setNote={setNote}
                    formError={errorConnection}
                    validation
                    showPersonButton
                    onClickCaringPersonEdit={onClickCaringPersonEdit}
                    onClickPersonInNeedOfCareEdit={onClickPersonInNeedOfCareEdit}
                />
                <CalculationBasisForm
                    disabled={isShowMode}
                    formErrorRV={errorCalculationBasisRV}
                    formErrorAV={errorCalculationBasisAV}
                    formErrorNursingTime={formErrorNursingTime}
                    formErrorNursingSupportMoney={formErrorNursingSupportMoney}
                    insuranceValues={insuranceValues}
                    dateFromRV={effectiveDateRV}
                    dateUntilRV={endingDateRV}
                    amountRV={amountRV}
                    amountFlagRV={amountFlagRV}
                    setDateFromRV={setEffectiveDateRV}
                    setDateUntilRV={setEndingDateRV}
                    setAmountRV={setAmountRV}
                    setAmountFlagRV={setAmountFlagRV}
                    dateFromAV={effectiveDateAV}
                    dateUntilAV={endingDateAV}
                    amountAV={amountAV}
                    amountFlagAV={amountFlagAV}
                    setDateFromAV={setEffectiveDateAV}
                    setDateUntilAV={setEndingDateAV}
                    setAmountAV={setAmountAV}
                    setAmountFlagAV={setAmountFlagAV}
                    dateFromNursingTime={effectiveDateNursingTime}
                    setDateFromNursingTime={setEffectiveDateNursingTime}
                    dateUntilNursingTime={endingDateNursingTime}
                    setDateUntilNursingTime={setEndingDateNursingTime}
                    healthInsuranceNursingTime={healthInsuranceNursingTime}
                    setHealthInsuranceNursingTime={setHealthInsuranceNursingTime}
                    nursingInsuranceNursingTime={nursingInsuranceNursingTime}
                    setNursingInsuranceNursingTime={setNursingInsuranceNursingTime}
                    amountHealthInsuranceNursingTime={amountHealthInsuranceNursingTime}
                    setAmountHealthInsuranceNursingTime={setAmountHealthInsuranceNursingTime}
                    amountNursingInsuranceNursingTime={amountNursingInsuranceNursingTime}
                    setAmountNursingInsuranceNursingTime={setAmountNursingInsuranceNursingTime}
                    percentageHealthInsuranceNursingTime={percentageHealthInsuranceNursingTime}
                    setPercentageHealthInsuranceNursingTime={setPercentageHealthInsuranceNursingTime}
                    percentageNursingInsuranceNursingTime={percentageNursingInsuranceNursingTime}
                    setPercentageNursingInsuranceNursingTime={setPercentageNursingInsuranceNursingTime}
                    amountHealthInsuranceBonusPUG={amountHealthInsuranceBonusPUG}
                    amountHealthInsurancePUG={amountHealthInsurancePUG}
                    amountPUG={amountPUG}
                    amountPensionInsurancePUG={amountPensionInsurancePUG}
                    amountUnemploymentInsurancePUG={amountUnemploymentInsurancePUG}
                    dateFromPUG={effectiveDatePUG}
                    dateUntilPUG={endingDatePUG}
                    healthInsurancePUG={healthInsurancePUG}
                    percentageHealthInsuranceBonusPUG={percentageHealthInsuranceBonusPUG}
                    percentageHealthInsurancePUG={percentageHealthInsurancePUG}
                    percentagePUG={percentagePUG}
                    percentagePensionInsurancePUG={percentagePensionInsurancePUG}
                    percentageUnemploymentInsurancePUG={percentageUnemploymentInsurancePUG}
                    setAmountHealthInsuranceBonusPUG={setAmountHealthInsuranceBonusPUG}
                    setAmountHealthInsurancePUG={setAmountHealthInsurancePUG}
                    setAmountPUG={setAmountPUG}
                    setAmountPensionInsurancePUG={setAmountPensionInsurancePUG}
                    setAmountUnemploymentInsurancePUG={setAmountUnemploymentInsurancePUG}
                    setDateFromPUG={setEffectiveDatePUG}
                    setDateUntilPUG={setEndingDatePUG}
                    setHealthInsurancePUG={setHealthInsurancePUG}
                    setPercentageHealthInsuranceBonusPUG={setPercentageHealthInsuranceBonusPUG}
                    setPercentageHealthInsurancePUG={setPercentageHealthInsurancePUG}
                    setPercentagePUG={setPercentagePUG}
                    setPercentagePensionInsurancePUG={setPercentagePensionInsurancePUG}
                    setPercentageUnemploymentInsurancePUG={setPercentageUnemploymentInsurancePUG}
                    calculationBasisListRV={calculationBasisListRV}
                    calculationBasisListAV={calculationBasisListAV}
                    setCalculationBasisListRV={setCalculationBasisListRV}
                    setCalculationBasisListAV={setCalculationBasisListAV}
                    nursingTime={nursingTimeList}
                    setNursingTime={setNursingTimeList}
                    nursingSupportMoneyList={nursingSupportMoneyList}
                    setNursingSupportMoneyList={setNursingSupportMoneyList}
                    earliestEffectiveDateCaringPerson={earliestEffectiveDateCaringPerson}
                    earliestEffectiveDatePersonInNeedOfCare={earliestEffectiveDatePersonInNeedOfCare}
                />
                <Divider></Divider>
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Historie der Berechnungsgrundlage"
                            defaultExpanded={false}
                            content={
                                <CrudTable
                                    tableData={historyData}
                                    columns={columns}
                                    sortBy={sortBy}
                                    fetchElementsWithId={fetchHistoryForPersonConnection}
                                    dataTypeName="Historieneintrag"
                                    dataFormatter={(element: PersonConnectionAPI) => {
                                        element.createTimestamp = formatTimeStampUserString(element.createTimestamp, element.createTransaction.user)
                                        if (element.deleteTimestamp === null || element.deleteTransaction === null) {
                                            if (element.deleteTimestamp === null) {
                                                element.deleteTimestamp = "";
                                            } else {
                                                element.deleteTimestamp = formatTimestampString(element.deleteTimestamp)
                                            }
                                        } else {
                                            element.deleteTimestamp = formatTimeStampUserString(element.deleteTimestamp, element.deleteTransaction.user)
                                        }
                                        element.createDescriptionString = element.createTransaction.description;
                                        element.deleteDescriptionString = element.deleteTransaction ? element.deleteTransaction?.description : ""
                                        return element;
                                    }}
                                    callOnFindInPageClick={onClickHistoryShow}
                                    hideSearchbar
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </PageLayout>
    );
}

export default PersonConnection;
