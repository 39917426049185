import { CaringPersonAPI } from "./caring-person";
import { Institution, InstitutionAPI } from "./institution";
import { NursingSupportMoneyAPI } from "./nursing-support-money";
import { PersonInNeedOfCare, PersonInNeedOfCareAPI } from "./person-in-need-of-care";
import { UserAPI } from "./user";

export interface AidConfig {
    id: string;
    institution: Institution
    autoImport: boolean
    aidLevels: AidLevel[]
    pensionRecords: InsuranceRecord[]
    unemploymentRecords: InsuranceRecord[]
    useSpecialConceptOfOrderPersonInNeedOfCare: boolean;
    labelConceptOfOrderCaringPerson: string;
    labelConceptOfOrderOnePersonInNeedOfCare: string;
    labelConceptOfOrderTwoPersonInNeedOfCare: string;
    labelExternalConceptOfOrderOnePersonInNeedOfCare: string;
    labelExternalConceptOfOrderTwoPersonInNeedOfCare: string;
    labelSpecialConceptOfOrderOnePersonInNeedOfCare: string;
    labelSpecialConceptOfOrderTwoPersonInNeedOfCare: string;
}

export type AidConfigAPI = {
    id: string;
    institution: InstitutionAPI
    autoImport: boolean
    aidLevels: AidLevelAPI[]
    pensionRecords: InsuranceRecordAPI[]
    unemploymentRecords: InsuranceRecordAPI[]
    useSpecialConceptOfOrderPersonInNeedOfCare: boolean;
    labelConceptOfOrderCaringPerson: string;
    labelConceptOfOrderOnePersonInNeedOfCare: string;
    labelConceptOfOrderTwoPersonInNeedOfCare: string;
    labelExternalConceptOfOrderOnePersonInNeedOfCare: string;
    labelExternalConceptOfOrderTwoPersonInNeedOfCare: string;
    labelSpecialConceptOfOrderOnePersonInNeedOfCare: string;
    labelSpecialConceptOfOrderTwoPersonInNeedOfCare: string;
}


export type AidConfigAPIRequest = {
    institutionId: string;
    autoImport: boolean
    aidLevels: AidLevelAPIRequest[]
    pensionRecords: InsuranceRecordRequestAPI[]
    unemploymentRecords: InsuranceRecordRequestAPI[]
    useSpecialConceptOfOrderPersonInNeedOfCare: boolean;
    labelConceptOfOrderCaringPerson: string;
    labelConceptOfOrderOnePersonInNeedOfCare: string;
    labelConceptOfOrderTwoPersonInNeedOfCare: string;
    labelExternalConceptOfOrderOnePersonInNeedOfCare: string;
    labelExternalConceptOfOrderTwoPersonInNeedOfCare: string;
    labelSpecialConceptOfOrderOnePersonInNeedOfCare: string;
    labelSpecialConceptOfOrderTwoPersonInNeedOfCare: string;
}

export type AidLevel = {
    id: string;
    aidConfiguration: AidConfig;
    percentage: number;
    validity: string;
    personsInNeedOfCareForAidLevel: PersonInNeedOfCare[];
}

export type AidLevelAPI = {
    id: string;
    percentage: number;
    validity: string;
    amountPersonInNeedOfCare: number;
    canBeDeleted: boolean;
}
export type AidLevelAPIRequest = {
    id: string;
    percentage: number;
    validity: string;
}

export type InsuranceRecord = {
    id: string;
    recordYear: number;
    percentage: number;
}

export type InsuranceRecordAPI = {
    id: string;
    recordYear: number;
    percentage: number;
}

export type InsuranceRecordRequestAPI = {
    recordYear: number;
    percentage: number;
}

export type NotImportedEntitiesAPI = {
    objectType: string;
    id: string;
    historyNumber: string;
    effectiveDate: string;
    name: string;
    createTimestamp: string;
    effectiveString: string;
};

export type CalculationRunAPI = {
    id: string;
    client: InstitutionAPI;
    startTimestamp: string;
    endTimestamp: string;
    status: string;
    runStartUser: UserAPI;
    amountCalculatedCaringPerson: number;
    sumRV: number;
    sumABV: number;
    sumAV: number;
    sumKV: number;
    sumCaringPersonKV: number;
    sumCaringPersonPV: number;
    amountToPay: number;
    statusString: string;
    startString: string;
    endString: string;
    startUserString: string;
    rvString: string;
    abvString: string;
    avString: string;
    kvString: string;
    caringPersonSumString: string;
    amountToPayString: string;
    amountGKVReports: number;
    runErrors: string[] | null;
};

export type CalculationNursingSupportMoneyAPI = {
    id: string;
    rvAvCalculated: boolean;
    calculatedPUG: number;
    calculatedKV: number;
    calculatedKVBonus: number;
    calculatedRV: number;
    calculatedABV: number;
    calculatedAV: number;
    processCompleted: boolean;
    nursingSupportMoney: NursingSupportMoneyAPI;
    caringPerson: CaringPersonAPI;
    personInNeedOfCare: PersonInNeedOfCareAPI;
    caringPersonName: string;
    personInNeedOfCareName: string;
    pugString: string;
    kvString: string;
    kvBonusString: string;
    rvString: string;
    abvString: string;
    avString: string;
};

export type CalculationExportApiRequest = {
    from: string;
    until: string;
}

export type CalculationRunSort = Pick<
    CalculationRunAPI,
    "id" | "startString" | "endString"
>;

export type CalculationBasisChangerAPIRequest = {
    effectiveDate: string;
    factor: number;
}

export type CalculationBasisChangerAPI = {
    id: string;
    effectiveDate: string;
    factor: number;
    changeStartTimestamp: string;
    changeEndTimestamp: string;
    status: string;
    amountChangedCalculationBasis: number;
    changeStartUser: UserAPI;
    changeStartUserString: string;
    statusString: string;
    effectiveDateString: string;
    changeStartTimestampString: string;
    changeEndTimestampString: string;
}

export type CalculationBasisChangerSort = Pick<
    CalculationBasisChangerAPI,
    "id" | "effectiveDate" | "factor"
>;



export type AidConfigAutoImport = {
    id: string;
    name: string;
    realValue: boolean;
}

export type AidConfigSpecialConceptOfOrder = {
    id: string;
    name: string;
    realValue: boolean;
}

export type AidConfigAidLevels = {
    id: string;
    name: string;
}

export type SosipImportAPI = {
    id: string;
    startTimestamp: string;
    endTimestamp: string;
    importUser: UserAPI;
    status: string;
    amountPersonInNeedOfCareCreated: number;
    amountCaringPersonCreated: number;
    amountPersonConnectionCreated: number;
    reportURL: string;
    startTimestampFormatted: string;
    endTimestampFormatted: string;
    statusFormatted: string;
    userName: string;
}

export type SosipImportSort = Pick<
    SosipImportAPI,
    "startTimestamp" | "endTimestamp" | "status"
>;

export type AidPlusImportAPI = {
    id: string;
    startTimestamp: string;
    endTimestamp: string;
    importUser: UserAPI;
    status: string;
    amountPersonInNeedOfCareCreated: number;
    amountCaringPersonCreated: number;
    amountPersonConnectionCreated: number;
    amountCalculationsCreated: number;
    reportURL: string;
    startTimestampFormatted: string;
    endTimestampFormatted: string;
    statusFormatted: string;
    userName: string;
}

export type AidPlusImportSort = Pick<
    AidPlusImportAPI,
    "startTimestamp" | "endTimestamp" | "status"
>;

export type AidPlusExportRequestAPI = {
    anonymizeFirstnameOfPersonInNeedOfCare: boolean,
    anonymizeLastnameOfPersonInNeedOfCare: boolean,
    anonymizedAddressOfPersonInNeedOfCare: boolean,
    clearNotePersonInNeedOfCare: boolean,
    anonymizeFirstnameOfCaringPerson: boolean,
    anonymizeLastnameOfCaringPerson: boolean,
    anonymizedAddressOfCaringPerson: boolean,
    clearNoteCaringPerson: boolean,
}

export type AidPlusExportAPI = {
    id: string;
    startTimestamp: string;
    endTimestamp: string;
    exportUser: UserAPI;
    status: string;
    outputURL: string;
    amountPersonInNeedOfCareExported: number,
    amountCaringPersonExported: number,
    amountPersonConnectionExported: number,
    amountCalculationRunsExported: number
    startTimestampFormatted: string;
    endTimestampFormatted: string;
    statusFormatted: string;
    userName: string;
}

export const getAutoImportType = () => {
    return [
        {
            id: "true",
            name: "Änderungen von Versicherungen automatisch importieren",
            realValue: true,
        },
        {
            id: "false",
            name: "Änderungen von Versicherung erst nach Genehmigung importieren",
            realValue: false,
        }
    ]
}

export const getSpecialConceptOfOrderType = () => {
    return [
        {
            id: "true",
            name: "Nutzung von Untermandanten (Felder für Spezielle Ordnungsbegriffe aktiviert)",
            realValue: true,
        },
        {
            id: "false",
            name: "Keine Untermandanten Nutzen (Felder für Spezielle Ordnungsbegriffe deaktiviert)",
            realValue: false,
        }
    ]
}

export const getSOSIPImportStatusValues = () => {
    return [
        {
            id: "E_SOSIP_IMPORT_STATUS_READING_DATA",
            name: "Datei einlesen"
        },
        {
            id: "E_SOSIP_IMPORT_STATUS_CREATING_DATA",
            name: "Daten erstellen"
        },
        {
            id: "E_SOSIP_IMPORT_STATUS_ERRORS",
            name: "Import mit Fehlern beendet"
        },
        {
            id: "E_SOSIP_IMPORT_STATUS_COMPLETED",
            name: "Import erfolgreich beendet"
        },
    ]
}

export const getAidPlusImportStatusValues = () => {
    return [
        {
            id: "E_AID_PLUS_IMPORT_RUNNING",
            name: "Laufend"
        },
        {
            id: "E_AID_PLUS_IMPORT_STATUS_ERRORS",
            name: "Import mit Fehlern beendet"
        },
        {
            id: "E_AID_PLUS_IMPORT_STATUS_COMPLETED",
            name: "Import erfolgreich beendet"
        },
    ]
}

export const getAidPlusExportStatusValues = () => {
    return [
        {
            id: "EAidPlusExportStatusRunning",
            name: "Laufend"
        },
        {
            id: "EAidPlusExportStatusFailed",
            name: "Fehlgeschlagen"
        },
        {
            id: "EAidPlusExportStatusCompleted",
            name: "Abgeschlossen"
        },
    ]
}