import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from "@mui/material";
import React from "react";
import ErrorDisplay from "./error-display";
import "./page-layout.css"

interface PageLayoutProps {
    title: string;
    children?: React.ReactNode;
    actions?: React.ReactNode;
}

function PageLayout({ title, children, actions }: PageLayoutProps) {
    return (
        <Card className="full-size flex-column page">
            <div className="page-start"></div>
            <CardHeader
                className="page-header"
                sx={{
                    backgroundColor: "#D4E7FF",
                    color: "#001D53",
                }}
                title={title}
            />
            <CardContent className="full-height page-content" sx={{
                backgroundColor: "#FFFFFF"
            }}>
                <ErrorDisplay>{children}</ErrorDisplay>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", backgroundColor: "#D4E7FF" }}>{actions}</CardActions>
        </Card>
    );
}

export default PageLayout;
