import { useEffect, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { } from "../../../../redux/auth.reducer";
import { fetchCaringPersonReport, fetchCaringPersons, makeCaringPersonInvalid } from "../../../../shared/requests/caring-person.requests";
import { CaringPersonFetchAllAPI, CaringPersonSort } from "../../../../models/caring-person";
import { useNavigate } from "react-router-dom";
import InvalidDialog from "../../../../shared/components/standard-actions/dialogs/invalid-dialog";
import { clearHistory } from "../../../../models/history";
import { formatCurrentDateForFileOutput, formatDateString, formatPersonNameWithValues, formatStatusStringWithValues, formatValidityString } from "../../../../helper/formatter";
import { saveAs } from 'file-saver';
import { InvalidAPIRequest } from "../../../../models/person";
import { hasEditPersonDataPermission, hasGetPersonDataPermission } from "../../../../helper/validation";

function ListCaringPersons() {
    const [sortBy] = useState<Sort<CaringPersonSort>>({
        key: "personName",
        direction: SortingDirection.Descending,
    });
    const [externalModified, setExternalModified] = useState<boolean>(false)
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [persons] = useState<CaringPersonFetchAllAPI[]>([]);
    const navigate = useNavigate();
    const [showInvalidDialog, setShowInvalidDialog] = useState<boolean>(false);
    const [entity, setEntity] = useState<CaringPersonFetchAllAPI | null>(null);
    const [clickedId, setClickedId] = useState<string>("");
    const onShowClick = (entity: CaringPersonFetchAllAPI) => {
        navigate(`show/${entity.id}`);
    }

    const onDownloadReportClicked = (entity: CaringPersonFetchAllAPI) => {
        fetchCaringPersonReport(entity.id).then(response => {
            saveAs(response.data, `${formatCurrentDateForFileOutput()}_Pflegeperson_Report_${entity.lastName}_${entity.firstName}`);
        })
    }

    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: CaringPersonFetchAllAPI) => {
        navigate(`edit/${entity.id}`);
    }
    const onInvalidClick = (entity: CaringPersonFetchAllAPI) => {
        setEntity(entity)
        setClickedId(entity.id)
        setShowInvalidDialog(true)
    }
    const onInvalidCancel = () => {
        setShowInvalidDialog(false)
    }

    const onInvalidSubmit = (value: InvalidAPIRequest | null) => {
        if (value !== null) {
            makeCaringPersonInvalid(clickedId ?? "", value).then((response) => {
                if (response.status === 200) {
                    setShowInvalidDialog(false)
                }
            });
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        clearHistory();
    });

    const columns: ColumnDefinitionType<CaringPersonFetchAllAPI, keyof CaringPersonFetchAllAPI>[] = [
        {
            key: "conceptOfOrderString",
            header: "OB/RV-Nummer",
        },
        {
            key: "personName",
            header: "Name, Vorname, Geburtsdatum",
        },
        {
            key: "effectiveDate",
            header: "Beginn",
        },
        {
            key: "validityString",
            header: "Gültigkeit",
        },
        {
            key: "statusString",
            header: "Schwebe",
        },
    ];

    const hasEditPermission = () => {
        if (user) {
            return hasEditPersonDataPermission(user?.permissions, null);
        } else {
            return false;
        }
    }

    const hasGetPermission = () => {
        if (user) {
            return hasGetPersonDataPermission(user?.permissions, null);
        } else {
            return false;
        }
    }
    return (
        <PageLayout title={"Pflegeperson Suche | Aktueller Zustand"}>
            <InvalidDialog
                showDialog={showInvalidDialog}
                dialogDatatypeName="einer Pflegeperson"
                dataTypeName="die Pflegeperson"
                personFirstName={entity?.firstName}
                personLastName={entity?.lastName}
                personGender={entity?.gender}
                personDateOfBirth={entity?.dateOfBirth}
                onClickCancel={onInvalidCancel}
                onClickSubmit={(value: InvalidAPIRequest | null) => onInvalidSubmit(value)}
            />
            <CrudTable
                externalModified={externalModified}
                setExternalModified={setExternalModified}
                callOnFindInPageClick={onShowClick}
                callOnAddClick={onAddClick}
                callOnEditClick={onEditClick}
                callOnInactiveClick={onInvalidClick}
                callOnDownloadClick={onDownloadReportClicked}
                downloadWithoutComplete={true}
                editPermission={hasEditPermission}
                getPermission={hasGetPermission}
                tableData={persons}
                columns={columns}
                sortBy={sortBy}
                showGender
                changeFloatingText
                dataTypeName="Pflegeperson"
                fetchElementsFloating={fetchCaringPersons}
                dataFormatter={(element: CaringPersonFetchAllAPI) => {
                    element.effectiveDate = formatDateString(element.effectiveDate)
                    element.personName = formatPersonNameWithValues(element.gender, element.firstName, element.lastName, element.dateOfBirth)
                    element.validityString = formatValidityString(element.validity)
                    element.statusString = formatStatusStringWithValues(element.status, element.userLastName, element.userFirstName)
                    element.conceptOfOrderString = element.conceptOfOrder && element.conceptOfOrder.length > 0 ? element.conceptOfOrder : element.pensionInsuranceNumber ? element.pensionInsuranceNumber : ""
                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ListCaringPersons;