
import { Button } from "@mui/material";
import { ArrowBack, Edit } from "@mui/icons-material";
import { clearHistory } from "../../../../models/history";

export interface SubmitProps {
    onClickBack: () => void;
    onClickEdit?: () => void;
}

function SubmitButtonsShow({
    onClickBack,
    onClickEdit
}: SubmitProps) {
    return (
        <>

            <Button variant="contained" onClick={onClickBack}>
                <ArrowBack sx={{ mr: 1 }}></ArrowBack>
                {"Zurück"}
            </Button>
            {onClickEdit &&
                <Button variant="contained" onClick={() => {
                    clearHistory();
                    onClickEdit();
                }}>
                    <Edit sx={{ mr: 1 }}></Edit>
                    {"Bearbeitung Beginnen"}
                </Button>}
        </>

    );
}

export default SubmitButtonsShow;
