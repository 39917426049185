import { axiosInstance, resourceAxiosInstance } from "../../config/axios.config";
import { AidConfigAPI, AidConfigAPIRequest, AidPlusExportAPI, AidPlusExportRequestAPI, CalculationBasisChangerAPI, CalculationBasisChangerAPIRequest, CalculationExportApiRequest, CalculationNursingSupportMoneyAPI, CalculationRunAPI, SosipImportAPI } from "../../models/aid";

export const fetchAidConfig = (institutionId: string) =>
    resourceAxiosInstance.get<AidConfigAPI>(`/aid/${institutionId}/configuration`, {
        cache: false,
    });

export const updateAidConfig = (aidConfig: Partial<AidConfigAPIRequest>) =>
    resourceAxiosInstance.patch<AidConfigAPIRequest>(
        `/aid/${aidConfig.institutionId}/configuration`,
        aidConfig,
        {
            cache: false,
        }
    );

export const startCalculationRuns = (institutionId: string) =>
    resourceAxiosInstance.post(`/aid/${institutionId}/calc-run`, {
        cache: false,
    });

export const fetchAllCalculationRuns = (institutionId: string) =>
    resourceAxiosInstance.get<CalculationRunAPI[]>(`/aid/${institutionId}/calc-run`, {
        cache: false,
    });

export const fetchABVReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/abv`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchABVReportDetailForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/abv/detail`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchDRVReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/drv`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchDRVReportDetailForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/drv/detail`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchAVReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/av`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchAVDetailReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/av/detail`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchNursingTimeDetailReportForCalculationRun = (institutionId: string, calculationRunId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/nursing-time/detail`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchGKVReportForCalculationRun = (institutionId: string, calculationRunId: string, reportIndex: number) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/report/${calculationRunId}/gkv/${reportIndex}`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchCaringPersonReportForPugCalculations = (institutionId: string, pugId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/pug/${pugId}`, {
        responseType: 'blob',
        cache: false,
    });

export const fetchCalculationRunCSVExport = (institutionId: string, data: CalculationExportApiRequest) =>
    resourceAxiosInstance.post<any>(`/aid/${institutionId}/calc-run/report`, data, {
        cache: false,
    });

export const startCalculationBasisChanger = (institutionId: string, changeParameters: CalculationBasisChangerAPIRequest) =>
    resourceAxiosInstance.post(`/aid/${institutionId}/calculation-basis-changer`, changeParameters, {
        cache: false,
    });

export const fetchAllCalculationBasisChanger = (institutionId: string) =>
    resourceAxiosInstance.get<CalculationBasisChangerAPI[]>(`/aid/${institutionId}/calculation-basis-changer`, {
        cache: false,
    });


export const fetchAllSosipImports = (institutionId: string) =>
    resourceAxiosInstance.get<SosipImportAPI[]>(`/aid/${institutionId}/sosip-import`, {
        cache: false,
    },);


export const startSosipImport = (institutionId: string, content: string) =>
    axiosInstance.post<any>(`/aid/${institutionId}/sosip-import`, content, {
        cache: false,
        headers: {
            'Content-Type': 'text/plain'
        },
    },);

export const fetchSosipImportReport = (institutionId: string, reportId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/sosip-import/${reportId}`, {
        responseType: 'blob',
        cache: false,
    },);

export const fetchAllAidPlusImports = (institutionId: string) =>
    resourceAxiosInstance.get<SosipImportAPI[]>(`/aid/${institutionId}/aidplus-import`, {
        cache: false,
    },);


export const startAidPlusImport = (institutionId: string, content: string) =>
    axiosInstance.post<any>(`/aid/${institutionId}/aidplus-import`, content, {
        cache: false,
        headers: {
            'Content-Type': 'text/plain'
        },
    },);

export const fetchAidPlusImportReport = (institutionId: string, reportId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/aidplus-import/${reportId}`, {
        responseType: 'blob',
        cache: false,
    },);

export const fetchAllAidPlusExports = (institutionId: string) =>
    resourceAxiosInstance.get<AidPlusExportAPI[]>(`/aid/${institutionId}/aidplus-export`, {
        cache: false,
    },);


export const startAidPlusExport = (institutionId: string, content: AidPlusExportRequestAPI) =>
    axiosInstance.post<any>(`/aid/${institutionId}/aidplus-export`, content, {
        cache: false,
    },);

export const fetchAidPlusExportFile = (institutionId: string, exportId: string) =>
    resourceAxiosInstance.get(`/aid/${institutionId}/aidplus-export/${exportId}`, {
        responseType: 'blob',
        cache: false,
    },);

export const fetchAllOpenPugs = (institutionId: string) =>
    axiosInstance.get<CalculationNursingSupportMoneyAPI[]>(`/aid/${institutionId}/pug`, {
        cache: false,
    },);

export const completeOpenPug = (institutionId: string, pugId: string) =>
    axiosInstance.patch<CalculationNursingSupportMoneyAPI>(`/aid/${institutionId}/pug/${pugId}`, {
        cache: false,
    },);


