import { useEffect, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { } from "../../../../redux/auth.reducer";
import { clearHistory } from "../../../../models/history";
import { formatCurrentDateForFileOutput, formatPersonName } from "../../../../helper/formatter";
import { saveAs } from 'file-saver';
import { CalculationNursingSupportMoneyAPI } from "../../../../models/aid";
import { completeOpenPug, fetchAllOpenPugs, fetchCaringPersonReportForPugCalculations } from "../../../../shared/requests/aid-request";
import ConfirmCancelDialog from "../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";

function ListOpenPugs() {
    const [sortBy] = useState<Sort<CalculationNursingSupportMoneyAPI>>({
        key: "id",
        direction: SortingDirection.Descending,
    });
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [nursingSupportMoneyCalculations] = useState<CalculationNursingSupportMoneyAPI[]>([]);
    const [externalModified, setExternalModified] = useState<boolean>(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [selectedCalculation, setSelectedCalculation] = useState<CalculationNursingSupportMoneyAPI | null>(null)

    const onDownloadReportClicked = (entity: CalculationNursingSupportMoneyAPI) => {
        if (user) {
            fetchCaringPersonReportForPugCalculations(user.institution.id, entity.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_Anschreibe_Pflegeperson_PUG`);
            })
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        clearHistory();
    });

    const columns: ColumnDefinitionType<CalculationNursingSupportMoneyAPI, keyof CalculationNursingSupportMoneyAPI>[] = [
        {
            key: "caringPersonName",
            header: "Pflegeperson",
        },
        {
            key: "personInNeedOfCareName",
            header: "Bedürftiger",
        },
        {
            key: "pugString",
            header: "Betrag PUG",
        },
        {
            key: "kvString",
            header: "Betrag KV",
        },
        {
            key: "kvBonusString",
            header: "Betrag KV Zusatzbeitrag ",
        },
        {
            key: "rvString",
            header: "Betrag RV ",
        },
        {
            key: "abvString",
            header: "Betrag ABV ",
        },
        {
            key: "avString",
            header: "Betrag AV ",
        },
    ];

    const onClickOpenCompleteDialog = (calculation: CalculationNursingSupportMoneyAPI) => {
        setShowDialog(true)
        setSelectedCalculation(calculation)
    }


    const onClickCancel = () => {
        setShowDialog(false)
        setSelectedCalculation(null)
    }

    const onClickSumbmit = () => {
        if (user && user.permissions.includes("nursing_support_money:complete") && selectedCalculation) {
            completeOpenPug(user.institution.id, selectedCalculation.id).then(() => {
                setShowDialog(false)
                setSelectedCalculation(null)
                setExternalModified(true)
            })
        }
    }

    const completeAllowed = () => {
        if (user) {
            return user.permissions.includes("nursing_support_money:complete");
        }
        return false;
    }

    return (
        <PageLayout title={"Offene Pflegeuntersützungsgelder"}>
            <ConfirmCancelDialog
                showDialog={showDialog}
                dialogTitle="Pflegeunterstützungsgeld abschließen"
                dialogInfo={`Soll das Pflegeunterstützungsgeld zwischen der Pflegeperson ${selectedCalculation?.caringPersonName} und dem Bedürftigen ${selectedCalculation?.personInNeedOfCareName} 
                wirklich abgeschlossen werden? Nach der Bestätigung ist dieses PUG nicht mehr in der Liste aufzufinden und das Anschreiben an die Pflegeperson kann nicht mehr heruntergeladen werden!`}
                onClickCancel={onClickCancel}
                onClickSubmit={onClickSumbmit}
            />
            {user && user.permissions.includes("nursing_support_money:get") &&
                <CrudTable
                    externalModified={externalModified}
                    setExternalModified={setExternalModified}
                    callOnDownloadClick={onDownloadReportClicked}
                    callOnCompleteClick={onClickOpenCompleteDialog}
                    editPermission={completeAllowed}
                    downloadWithoutComplete={true}
                    dataTypeName="PUG"
                    showGender
                    tableData={nursingSupportMoneyCalculations}
                    columns={columns}
                    sortBy={sortBy}
                    fetchElementsWithId={fetchAllOpenPugs}
                    searchingId={user?.institution.id}
                    dataFormatter={(element: CalculationNursingSupportMoneyAPI) => {
                        element.pugString = element.calculatedPUG + "€"
                        element.kvString = element.calculatedKV + "€"
                        element.kvBonusString = element.calculatedKVBonus + "€"
                        element.rvString = element.calculatedRV + "€"
                        element.abvString = element.calculatedABV + "€"
                        element.avString = element.calculatedAV + "€"
                        element.caringPersonName = formatPersonName(element.caringPerson.person)
                        element.personInNeedOfCareName = formatPersonName(element.personInNeedOfCare.person)
                        return element;
                    }}
                />
            }
        </PageLayout>
    );
}

export default ListOpenPugs;
