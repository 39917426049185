import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StatusSelect, ValiditySelect, getStatusValues, getValidityValues } from '../../../../models/historization';
import HistoryShow from '../../../../shared/components/history-show/history-show';
import { fetchCaringPersons, fetchHistoryForCaringPerson } from '../../../../shared/requests/caring-person.requests';
import { PersonInNeedOfCareAPI, PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { fetchSingleHistoryForPersonConnection } from '../../../../shared/requests/calculation-basis.requests';
import PersonConnectionForm from '../form/person-connection-form';
import { fetchHistoryForPersonInNeedOfCare, fetchPersonsInNeedOfCare } from '../../../../shared/requests/person-in-need-of-care.requests';
import { CaringPersonAPI, CaringPersonSelect } from '../../../../models/caring-person';
import CalculationBasisForm from '../form/calculation-basis-form';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI } from '../../../../models/calculation-basis';
import { addToHistory, HistoryTimelineItemModel, updateHistoryUntilIndex } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';
import SubmitButtonsShow from '../../../../shared/components/standard-actions/submit-buttons/submit-button-show';
import { InstitutionSelect } from '../../../../models/institution';
import { fetchInsurances } from '../../../../shared/requests/institution.requests';
import { NursingTimeAPI } from '../../../../models/nursing-time';
import { NursingSupportMoneyAPI } from '../../../../models/nursing-support-money';

function PersonConnectionHistory() {
    //Select Listen
    const [caringPersonValues, setCaringPersonValues] = useState<CaringPersonSelect[]>([]);
    const [personInNeedOfCareValues, setPersonInNeedOfCareValues] = useState<PersonInNeedOfCareSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    //Berechnungsgrundlage extra Daten
    const [caringPerson, setCaringPerson] = useState<CaringPersonSelect | null>(null);
    const [personInNeedOfCare, setPersonInNeedOfCare] = useState<PersonInNeedOfCareSelect | null>(null);
    const [effectiveDateRV, setEffectiveDateRV] = useState<string>("");
    const [endingDateRV, setEndingDateRV] = useState<string | null>(null);
    const [amountRV, setAmountRV] = useState<number>(0.0);
    const [amountFlagRV, setAmountFlagRV] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [effectiveDateAV, setEffectiveDateAV] = useState<string>("");
    const [endingDateAV, setEndingDateAV] = useState<string | null>(null);
    const [amountAV, setAmountAV] = useState<number>(0.0);
    const [amountFlagAV, setAmountFlagAV] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [effectiveDateNursingTime, setEffectiveDateNursingTime] = useState<string>("");
    const [endingDateNursingTime, setEndingDateNursingTime] = useState<string>("");
    const [healthInsuranceNursingTime, setHealthInsuranceNursingTime] = useState<InstitutionSelect | null>(null);
    const [nursingInsuranceNursingTime, setNursingInsuranceNursingTime] = useState<InstitutionSelect | null>(null);
    const [amountHealthInsuranceNursingTime, setAmountHealthInsuranceNursingTime] = useState<number>(0.0);
    const [amountNursingInsuranceNursingTime, setAmountNursingInsuranceNursingTime] = useState<number>(0.0);
    const [percentageHealthInsuranceNursingTime, setPercentageHealthInsuranceNursingTime] = useState<number>(0.0);
    const [percentageNursingInsuranceNursingTime, setPercentageNursingInsuranceNursingTime] = useState<number>(0.0);
    const [effectiveDatePUG, setEffectiveDatePUG] = useState<string>("");
    const [endingDatePUG, setEndingDatePUG] = useState<string>("");
    const [healthInsurancePUG, setHealthInsurancePUG] = useState<InstitutionSelect | null>(null);
    const [amountPUG, setAmountPUG] = useState<number>(0.0);
    const [percentagePUG, setPercentagePUG] = useState<number>(0.0);
    const [amountHealthInsurancePUG, setAmountHealthInsurancePUG] = useState<number>(0.0);
    const [percentageHealthInsurancePUG, setPercentageHealthInsurancePUG] = useState<number>(0.0);
    const [amountHealthInsuranceBonusPUG, setAmountHealthInsuranceBonusPUG] = useState<number>(0.0);
    const [percentageHealthInsuranceBonusPUG, setPercentageHealthInsuranceBonusPUG] = useState<number>(0.0);
    const [amountPensionInsurancePUG, setAmountPensionInsurancePUG] = useState<number>(0.0);
    const [percentagePensionInsurancePUG, setPercentagePensionInsurancePUG] = useState<number>(0.0);
    const [amountUnemploymentInsurancePUG, setAmountUnemploymentInsurancePUG] = useState<number>(0.0);
    const [percentageUnemploymentInsurancePUG, setPercentageUnemploymentInsurancePUG] = useState<number>(0.0);
    const [nursingTimeList, setNursingTimeList] = useState<NursingTimeAPI[]>([]);
    const [nursingSupportMoneyList, setNursingSupportMoneyList] = useState<NursingSupportMoneyAPI[]>([]);
    const [note, setNote] = useState<string | null>("");

    //Historisierungsdaten
    const [validity, setValidity] = useState<ValiditySelect | null>(null);
    const [status, setStatus] = useState<StatusSelect | null>(null);
    const [createTimestamp, setCreateTimestamp] = useState<string>("");
    const [deleteTimestamp, setDeleteTimestamp] = useState<string>("");
    const [createTransactionID, setCreateTransactionID] = useState<string>("");
    const [createTransactionUserEmail, setCreateTransactionUserEmail] = useState<string>("");
    const [deleteTransactionID, setDeleteTransactionID] = useState<string>("");
    const [deleteTransactionUserEmail, setDeleteTransactionUserEmail] = useState<string>("");
    const [imported, setImported] = useState<boolean>(true);
    const [calculationBasisListRV, setCalculationBasisListRV] = useState<CalculationBasisAPI[]>([]);
    const [calculationBasisListAV, setCalculationBasisListAV] = useState<CalculationBasisAPI[]>([]);
    const [earliestEffectiveDatePersonInNeedOfCare, setEarliestEffectiveDatePersonInNeedOfCare] = useState<string>("");
    const [earliestEffectiveDateCaringPerson, setEarliestEffectiveDateCaringPerson] = useState<string>("");
    //Systemdaten
    const { id, history } = useParams();
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);

    const navigate = useNavigate();

    const goBackToHome = () => {
        navigate("/home")
    }

    useEffect(() => {
        fetchPersonsInNeedOfCare(true).then((response) => {
            let personsInNeedOfCare = response.data;
            let personsSelect: PersonInNeedOfCareSelect[] = [];
            personsInNeedOfCare.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.lastName + ", " + person.firstName,
                    realValue: +person.id,
                    genderId: person.gender
                })
            })
            setPersonInNeedOfCareValues(personsSelect)
        });
        fetchCaringPersons(true).then((response) => {
            let caringPersons = response.data;
            let personsSelect: CaringPersonSelect[] = [];
            caringPersons.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.lastName + ", " + person.firstName,
                    realValue: +person.id,
                    genderId: person.gender
                })
            })
            setCaringPersonValues(personsSelect)
        });
        fetchInsurancesWithMaybeOffset();
        if (id && history) {
            fetchSingleHistoryForPersonConnection(id, history).then((response) => {
                const person = response.data;
                const cp = {
                    id: person.caringPerson.id,
                    name: person.caringPerson.person.lastname + ", " + person.caringPerson.person.firstname,
                    realValue: +person.caringPerson.id,
                    genderId: person.caringPerson.person.gender
                }
                const pinoc = {
                    id: person.personInNeedOfCare.id,
                    name: person.personInNeedOfCare.person.lastname + ", " + person.personInNeedOfCare.person.firstname,
                    realValue: +person.personInNeedOfCare.id,
                    genderId: person.personInNeedOfCare.person.gender
                };
                setCaringPerson(cp);
                setPersonInNeedOfCare(pinoc);
                setNote(person.note)
                if (pinoc != null) {
                    setEarliestEffectiveDateForPersonInNeedOfCareWithID(pinoc.id)
                }
                if (cp != null) {
                    setEarliestEffectiveDateForCaringPersonWithID(cp.id)
                }
                setCalculationBasisListRV(response.data.calculationBasisListRV.sort((a, b) => a.from > b.from ? 1 : -1))
                setCalculationBasisListAV(response.data.calculationBasisListAV.sort((a, b) => a.from > b.from ? 1 : -1))
                setNursingSupportMoneyList(person.nursingSupportMoneyList.sort((a, b) => a.from > b.from ? -1 : 1))
                setNursingTimeList(person.nursingTimeList.sort((a, b) => a.from > b.from ? -1 : 1))
                //Historiendaten setzen
                const element = getValidityValues().find((element) => { return response.data.validity === element.id; });
                if (element) {
                    setValidity(element);
                }
                const elementStatus = getStatusValues().find((element) => { return response.data.status === element.id; });
                if (elementStatus) {
                    setStatus(elementStatus);
                }
                setImported(response.data.imported)
                setCreateTimestamp(response.data.createTimestamp);
                setCreateTransactionID(response.data.createTransaction.id)
                setCreateTransactionUserEmail(response.data.createTransaction.user.email)
                if (response.data.deleteTimestamp != null) {
                    setDeleteTimestamp(response.data.deleteTimestamp);
                }
                if (response.data.deleteTransaction != null) {
                    setDeleteTransactionID(response.data.deleteTransaction.id);
                    setDeleteTransactionUserEmail(response.data.deleteTransaction.user.email)
                }
                if (!isCurrentRendered) {
                    const historyData: HistoryTimelineItemModel[] = [];
                    addToHistory({
                        type: "Historie",
                        name: `${response.data.personInNeedOfCare.person.lastname}, ${response.data.personInNeedOfCare.person.firstname} | ${response.data.caringPerson.person.lastname}, ${response.data.caringPerson.person.firstname}`,
                        url: window.location.pathname,
                        id: id
                    }).forEach((element => {
                        historyData.push({
                            type: element.type,
                            title: element.name,
                            url: element.url,
                            id: element.id
                        })
                    }))
                    setHistoryChronoItems(historyData);
                }

            });
            setCurrentRendered(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        if (!imported) {
            return "Berechnungsgrundlage Historiendetails anzeigen"
        } else {
            return "Berechnungsgrundlage importieren"
        }
    };

    const onClickBack = () => {
        if (historyChronoItems.length > 1) {
            const path: string = historyChronoItems[historyChronoItems.length - 2].url
            updateHistoryUntilIndex(historyChronoItems.length - 2)
            navigate(path)
        } else {
            goBackToHome();
        }
    }

    const setPersonInNeedOfCareValue = (value: string) => {
        const person: PersonInNeedOfCareSelect | null = personInNeedOfCareValues.find(({ name }) => name === value) || null
        setPersonInNeedOfCare(person)
        if (person != null) {
            setEarliestEffectiveDateForPersonInNeedOfCareWithID(person.id)
        }
    }

    const setCaringPersonValue = (value: string) => {
        const person: CaringPersonSelect | null = caringPersonValues.find(({ name }) => name === value) || null
        setCaringPerson(person)
        if (person != null) {
            setEarliestEffectiveDateForCaringPersonWithID(person.id)
        }
    }

    const fetchInsurancesWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            setInsuranceValues(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
    }

    const setEarliestEffectiveDateForCaringPersonWithID = (id: string) => {
        fetchHistoryForCaringPerson(id).then((response2) => {
            const response: CaringPersonAPI[] = response2.data.filter(a => a.deleteTransaction === null).sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                return 0;
            })
            if (response.length >= 1) {
                setEarliestEffectiveDateCaringPerson(response[0].effectiveDate)
            }
        })
    }

    const setEarliestEffectiveDateForPersonInNeedOfCareWithID = (id: string) => {
        fetchHistoryForPersonInNeedOfCare(id).then((response2) => {
            const response: PersonInNeedOfCareAPI[] = response2.data.filter(a => a.deleteTransaction === null && a.validity === "VALIDITY_VALID").sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                return 0;
            })
            if (response.length >= 1) {
                setEarliestEffectiveDatePersonInNeedOfCare(response[0].effectiveDate)
            }
        })
    }

    const actionsShow = (
        <SubmitButtonsShow
            onClickBack={onClickBack}
        />
    );


    return (
        <PageLayout title={getUserTitle()} actions={actionsShow}>
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                />}
                <PersonConnectionForm
                    caringPerson={caringPerson}
                    caringPersonValues={caringPersonValues}
                    disabled={true}
                    personInNeedOfCare={personInNeedOfCare}
                    personInNeedOfCareValues={personInNeedOfCareValues}
                    savePushed={false}
                    setCaringPersonValue={setCaringPersonValue}
                    setPersonInNeedOfCareValue={setPersonInNeedOfCareValue}
                    earliestEffectiveDateCaringPerson={earliestEffectiveDateCaringPerson}
                    earliestEffectiveDatePersonInNeedOfCare={earliestEffectiveDatePersonInNeedOfCare}
                    setEarliestEffectiveDateCaringPerson={setEarliestEffectiveDateCaringPerson}
                    setEarliestEffectiveDatePersonInNeedOfCare={setEarliestEffectiveDatePersonInNeedOfCare}
                    note={note}
                    setNote={setNote}
                    formError={false}
                    showPersonButton={false}
                />
                <CalculationBasisForm
                    disabled={true}
                    formErrorRV={false}
                    formErrorAV={false}
                    formErrorNursingTime={false}
                    formErrorNursingSupportMoney={false}
                    insuranceValues={insuranceValues}
                    dateFromRV={effectiveDateRV}
                    dateUntilRV={endingDateRV}
                    amountRV={amountRV}
                    amountFlagRV={amountFlagRV}
                    setDateFromRV={setEffectiveDateRV}
                    setDateUntilRV={setEndingDateRV}
                    setAmountRV={setAmountRV}
                    setAmountFlagRV={setAmountFlagRV}
                    dateFromAV={effectiveDateAV}
                    dateUntilAV={endingDateAV}
                    amountAV={amountAV}
                    amountFlagAV={amountFlagAV}
                    setDateFromAV={setEffectiveDateAV}
                    setDateUntilAV={setEndingDateAV}
                    setAmountAV={setAmountAV}
                    setAmountFlagAV={setAmountFlagAV}
                    dateFromNursingTime={effectiveDateNursingTime}
                    setDateFromNursingTime={setEffectiveDateNursingTime}
                    dateUntilNursingTime={endingDateNursingTime}
                    setDateUntilNursingTime={setEndingDateNursingTime}
                    healthInsuranceNursingTime={healthInsuranceNursingTime}
                    setHealthInsuranceNursingTime={setHealthInsuranceNursingTime}
                    nursingInsuranceNursingTime={nursingInsuranceNursingTime}
                    setNursingInsuranceNursingTime={setNursingInsuranceNursingTime}
                    amountHealthInsuranceNursingTime={amountHealthInsuranceNursingTime}
                    setAmountHealthInsuranceNursingTime={setAmountHealthInsuranceNursingTime}
                    amountNursingInsuranceNursingTime={amountNursingInsuranceNursingTime}
                    setAmountNursingInsuranceNursingTime={setAmountNursingInsuranceNursingTime}
                    percentageHealthInsuranceNursingTime={percentageHealthInsuranceNursingTime}
                    setPercentageHealthInsuranceNursingTime={setPercentageHealthInsuranceNursingTime}
                    percentageNursingInsuranceNursingTime={percentageNursingInsuranceNursingTime}
                    setPercentageNursingInsuranceNursingTime={setPercentageNursingInsuranceNursingTime}
                    amountHealthInsuranceBonusPUG={amountHealthInsuranceBonusPUG}
                    amountHealthInsurancePUG={amountHealthInsurancePUG}
                    amountPUG={amountPUG}
                    amountPensionInsurancePUG={amountPensionInsurancePUG}
                    amountUnemploymentInsurancePUG={amountUnemploymentInsurancePUG}
                    dateFromPUG={effectiveDatePUG}
                    dateUntilPUG={endingDatePUG}
                    healthInsurancePUG={healthInsurancePUG}
                    percentageHealthInsuranceBonusPUG={percentageHealthInsuranceBonusPUG}
                    percentageHealthInsurancePUG={percentageHealthInsurancePUG}
                    percentagePUG={percentagePUG}
                    percentagePensionInsurancePUG={percentagePensionInsurancePUG}
                    percentageUnemploymentInsurancePUG={percentageUnemploymentInsurancePUG}
                    setAmountHealthInsuranceBonusPUG={setAmountHealthInsuranceBonusPUG}
                    setAmountHealthInsurancePUG={setAmountHealthInsurancePUG}
                    setAmountPUG={setAmountPUG}
                    setAmountPensionInsurancePUG={setAmountPensionInsurancePUG}
                    setAmountUnemploymentInsurancePUG={setAmountUnemploymentInsurancePUG}
                    setDateFromPUG={setEffectiveDatePUG}
                    setDateUntilPUG={setEndingDatePUG}
                    setHealthInsurancePUG={setHealthInsurancePUG}
                    setPercentageHealthInsuranceBonusPUG={setPercentageHealthInsuranceBonusPUG}
                    setPercentageHealthInsurancePUG={setPercentageHealthInsurancePUG}
                    setPercentagePUG={setPercentagePUG}
                    setPercentagePensionInsurancePUG={setPercentagePensionInsurancePUG}
                    setPercentageUnemploymentInsurancePUG={setPercentageUnemploymentInsurancePUG}
                    calculationBasisListRV={calculationBasisListRV}
                    calculationBasisListAV={calculationBasisListAV}
                    setCalculationBasisListRV={setCalculationBasisListRV}
                    setCalculationBasisListAV={setCalculationBasisListAV}
                    nursingTime={nursingTimeList}
                    setNursingTime={setNursingTimeList}
                    nursingSupportMoneyList={nursingSupportMoneyList}
                    setNursingSupportMoneyList={setNursingSupportMoneyList}
                    earliestEffectiveDateCaringPerson={earliestEffectiveDateCaringPerson}
                    earliestEffectiveDatePersonInNeedOfCare={earliestEffectiveDatePersonInNeedOfCare}
                />

                <HistoryShow
                    validity={validity}
                    status={status}
                    createTimestamp={createTimestamp}
                    deleteTimestamp={deleteTimestamp}
                    createTransactionID={createTransactionID}
                    createTransactionUserEmail={createTransactionUserEmail}
                    deleteTransactionID={deleteTransactionID}
                    deleteTransactionUserEmail={deleteTransactionUserEmail}
                />


            </div>
        </PageLayout >
    );
}

export default PersonConnectionHistory;
