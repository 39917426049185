import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import SubmitButtonsErrorDialog from "../submit-buttons/submit-button-dialog-confirm";
import { CalculationRunAPI } from "../../../../models/aid";
import { fetchABVReportDetailForCalculationRun, fetchABVReportForCalculationRun, fetchAVDetailReportForCalculationRun, fetchAVReportForCalculationRun, fetchDRVReportDetailForCalculationRun, fetchDRVReportForCalculationRun, fetchGKVReportForCalculationRun, fetchNursingTimeDetailReportForCalculationRun } from "../../../requests/aid-request";
import { saveAs } from 'file-saver';
import { formatCurrentDateForFileOutput, formatYearMonthForFileOutput } from "../../../../helper/formatter";
import { FileDownload } from "@mui/icons-material";

export interface ConfirmCancelProps {
    showDialog: boolean;
    onClickCancel: () => void;
    calculationRun: CalculationRunAPI | null;
}

const DownloadCalculationReportsDialog = ({
    showDialog,
    onClickCancel,
    calculationRun

}: ConfirmCancelProps) => {
    const theme: Theme = useTheme();

    const downloadDRVReport = () => {
        if (calculationRun !== null) {
            fetchDRVReportForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_DRV_Anschreiben_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadDRVDetailReport = () => {
        if (calculationRun !== null) {
            fetchDRVReportDetailForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_DRV_Einzelnachweis_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadABVReport = () => {
        if (calculationRun !== null) {
            fetchABVReportForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_ABV_Anschreiben_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadABVDetailReport = () => {
        if (calculationRun !== null) {
            fetchABVReportDetailForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_ABV_Einzelnachweis_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadAVReport = () => {
        if (calculationRun !== null) {
            fetchAVReportForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_AV_Anschreiben_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadAVDetailReport = () => {
        if (calculationRun !== null) {
            fetchAVDetailReportForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_AV_Einzelnachweis_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadNursingTimeDetailReport = () => {
        if (calculationRun !== null) {
            fetchNursingTimeDetailReportForCalculationRun(calculationRun.client.id, calculationRun.id).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_Pflegezeit_Einzelnachweis_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const downloadGKVReport = (index: number) => {
        if (calculationRun !== null) {
            fetchGKVReportForCalculationRun(calculationRun.client.id, calculationRun.id, index).then(response => {
                saveAs(response.data, `${formatCurrentDateForFileOutput()}_GKV_Anschreiben(${index})_${formatYearMonthForFileOutput(calculationRun.startTimestamp)}`);
            })
        }
    }

    const createGKVButtons = () => {
        const response = []
        if (calculationRun !== null) {
            let amount = calculationRun.amountGKVReports;
            let i = 1;
            while (amount >= 2) {
                const c = i;
                response.push(
                    <div className="dialog-report-single-group">
                        <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%" }} onClick={() => downloadGKVReport(c)}>{`Anschreiben GKV (${c})`}</Button>
                        <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%", marginLeft: "5px" }} onClick={() => downloadGKVReport(c + 1)}>{`Anschreiben GKV (${c + 1})`}</Button>
                    </div>
                )
                i = i + 2;
                amount = amount - 2;
            }
            if (amount === 1) {
                response.push(
                    <div className="dialog-report-single-group">
                        <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%" }} onClick={() => downloadGKVReport(i)}>{`Anschreiben GKV (${i})`}</Button>
                    </div>
                )
            }
        }
        return response
    }

    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                {"Herunterladen von Dateien für den Berechnungslauf"}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        {"Folgende Dateien stehen zum Download bereit:"}
                    </DialogContentText>
                    {calculationRun &&
                        <div className="dialog-report-group">
                            <div className="dialog-report-single-group">
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%" }} onClick={downloadDRVReport}>Anschreiben DRV</Button>
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%", marginLeft: "5px" }} onClick={downloadDRVDetailReport}>Einzelnachweis DRV</Button>
                            </div>
                            <div className="dialog-report-single-group">
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%" }} onClick={downloadABVReport}>Anschreiben ABV</Button>
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%", marginLeft: "5px" }} onClick={downloadABVDetailReport}>Einzelnachweis ABV</Button>
                            </div>
                            <div className="dialog-report-single-group">
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%" }} onClick={downloadAVReport}>Anschreiben AV</Button>
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%", marginLeft: "5px" }} onClick={downloadAVDetailReport}>Einzelnachweis AV</Button>
                            </div>
                            <div className="dialog-report-single-group">
                                <Button startIcon={<FileDownload />} variant="contained" sx={{ width: "50%", marginLeft: "5px" }} onClick={downloadNursingTimeDetailReport}>Einzelnachweis Pflegezeit (Abzuführende Beiträge an Pflegepersonen)</Button>
                            </div>
                            {createGKVButtons()}
                        </div>
                    }
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsErrorDialog
                    submitText="Download-Menü schließen"
                    onClickSubmit={onClickCancel}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DownloadCalculationReportsDialog;
