import AccordionComponent from '../../../../shared/components/accordion-component';
import { Add, Delete, Edit, Insights } from '@mui/icons-material';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI, getCalculationBasisAmountFlag } from '../../../../models/calculation-basis';
import SelectComponent from '../../../../shared/components/select-component';
import { Button, Checkbox, Divider, FormControlLabel, IconButton, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { List } from 'reactstrap';
import { formatCalculationBasisListEntry, formatNursingSupportMoneyListEntry, formatNursingTimeListEntry } from '../../../../helper/formatter';
import { datesOverlapping, endingDateSameOrAfterEffectiveDate } from '../../../../helper/validation';
import { useState } from 'react';
import ErrorDialog from '../../../../shared/components/standard-actions/dialogs/error-dialog';
import { InstitutionSelect } from '../../../../models/institution';
import { NursingTimeAPI } from '../../../../models/nursing-time';
import { NursingSupportMoneyAPI } from '../../../../models/nursing-support-money';

type SelectProps = {
    disabled: boolean
    formErrorRV: boolean;
    formErrorAV: boolean;
    formErrorNursingTime: boolean;
    formErrorNursingSupportMoney: boolean;
    validation?: boolean;
    insuranceValues: InstitutionSelect[];
    dateFromRV: string;
    setDateFromRV: (value: string) => void;
    dateUntilRV: string | null;
    setDateUntilRV: (value: string | null) => void;
    amountRV: number;
    setAmountRV: (value: number) => void;
    amountFlagRV: CalculationBasisAmountFlagSelect | null;
    setAmountFlagRV: (value: CalculationBasisAmountFlagSelect | null) => void;
    calculationBasisListRV: CalculationBasisAPI[];
    setCalculationBasisListRV: (value: CalculationBasisAPI[]) => void;
    dateFromAV: string;
    setDateFromAV: (value: string) => void;
    dateUntilAV: string | null;
    setDateUntilAV: (value: string | null) => void;
    amountAV: number;
    setAmountAV: (value: number) => void;
    amountFlagAV: CalculationBasisAmountFlagSelect | null;
    setAmountFlagAV: (value: CalculationBasisAmountFlagSelect | null) => void;
    calculationBasisListAV: CalculationBasisAPI[];
    setCalculationBasisListAV: (value: CalculationBasisAPI[]) => void;
    dateFromNursingTime: string;
    setDateFromNursingTime: (value: string) => void;
    dateUntilNursingTime: string;
    setDateUntilNursingTime: (value: string) => void;
    healthInsuranceNursingTime: InstitutionSelect | null;
    setHealthInsuranceNursingTime: (value: InstitutionSelect | null) => void;
    amountHealthInsuranceNursingTime: number;
    setAmountHealthInsuranceNursingTime: (value: number) => void;
    percentageHealthInsuranceNursingTime: number;
    setPercentageHealthInsuranceNursingTime: (value: number) => void;
    nursingInsuranceNursingTime: InstitutionSelect | null;
    setNursingInsuranceNursingTime: (value: InstitutionSelect | null) => void;
    amountNursingInsuranceNursingTime: number;
    setAmountNursingInsuranceNursingTime: (value: number) => void;
    percentageNursingInsuranceNursingTime: number;
    setPercentageNursingInsuranceNursingTime: (value: number) => void;
    nursingTime: NursingTimeAPI[];
    setNursingTime: (value: NursingTimeAPI[]) => void;
    dateFromPUG: string;
    setDateFromPUG: (value: string) => void;
    dateUntilPUG: string;
    setDateUntilPUG: (value: string) => void;
    healthInsurancePUG: InstitutionSelect | null;
    setHealthInsurancePUG: (value: InstitutionSelect | null) => void;
    amountPUG: number;
    setAmountPUG: (value: number) => void;
    percentagePUG: number;
    setPercentagePUG: (value: number) => void;
    amountHealthInsurancePUG: number;
    setAmountHealthInsurancePUG: (value: number) => void;
    percentageHealthInsurancePUG: number;
    setPercentageHealthInsurancePUG: (value: number) => void;
    amountHealthInsuranceBonusPUG: number;
    setAmountHealthInsuranceBonusPUG: (value: number) => void;
    percentageHealthInsuranceBonusPUG: number;
    setPercentageHealthInsuranceBonusPUG: (value: number) => void;
    amountPensionInsurancePUG: number;
    setAmountPensionInsurancePUG: (value: number) => void;
    percentagePensionInsurancePUG: number;
    setPercentagePensionInsurancePUG: (value: number) => void;
    amountUnemploymentInsurancePUG: number;
    setAmountUnemploymentInsurancePUG: (value: number) => void;
    percentageUnemploymentInsurancePUG: number;
    setPercentageUnemploymentInsurancePUG: (value: number) => void;
    nursingSupportMoneyList: NursingSupportMoneyAPI[];
    setNursingSupportMoneyList: (value: NursingSupportMoneyAPI[]) => void;
    earliestEffectiveDateCaringPerson: string;
    earliestEffectiveDatePersonInNeedOfCare: string;
};

const CalculationBasisForm = ({
    disabled,
    formErrorRV,
    formErrorAV,
    formErrorNursingTime,
    formErrorNursingSupportMoney,
    validation,
    insuranceValues,
    dateFromRV,
    setDateFromRV,
    dateUntilRV,
    setDateUntilRV,
    amountRV,
    setAmountRV,
    amountFlagRV,
    setAmountFlagRV,
    calculationBasisListRV,
    dateFromAV,
    setDateFromAV,
    dateUntilAV,
    setDateUntilAV,
    amountAV,
    setAmountAV,
    amountFlagAV,
    setAmountFlagAV,
    setCalculationBasisListRV,
    calculationBasisListAV,
    setCalculationBasisListAV,
    dateFromNursingTime,
    setDateFromNursingTime,
    dateUntilNursingTime,
    setDateUntilNursingTime,
    healthInsuranceNursingTime,
    setHealthInsuranceNursingTime,
    amountHealthInsuranceNursingTime,
    setAmountHealthInsuranceNursingTime,
    percentageHealthInsuranceNursingTime,
    setPercentageHealthInsuranceNursingTime,
    nursingInsuranceNursingTime,
    setNursingInsuranceNursingTime,
    amountNursingInsuranceNursingTime,
    setAmountNursingInsuranceNursingTime,
    percentageNursingInsuranceNursingTime,
    setPercentageNursingInsuranceNursingTime,
    nursingTime,
    setNursingTime,
    dateFromPUG,
    setDateFromPUG,
    dateUntilPUG,
    setDateUntilPUG,
    healthInsurancePUG,
    setHealthInsurancePUG,
    amountPUG,
    setAmountPUG,
    percentagePUG,
    setPercentagePUG,
    amountHealthInsurancePUG,
    setAmountHealthInsurancePUG,
    percentageHealthInsurancePUG,
    setPercentageHealthInsurancePUG,
    amountHealthInsuranceBonusPUG,
    setAmountHealthInsuranceBonusPUG,
    percentageHealthInsuranceBonusPUG,
    setPercentageHealthInsuranceBonusPUG,
    amountPensionInsurancePUG,
    setAmountPensionInsurancePUG,
    percentagePensionInsurancePUG,
    setPercentagePensionInsurancePUG,
    amountUnemploymentInsurancePUG,
    setAmountUnemploymentInsurancePUG,
    percentageUnemploymentInsurancePUG,
    setPercentageUnemploymentInsurancePUG,
    nursingSupportMoneyList,
    setNursingSupportMoneyList,
    earliestEffectiveDateCaringPerson,
    earliestEffectiveDatePersonInNeedOfCare

}: SelectProps) => {

    const [addPushed, setAddPushed] = useState<boolean>(false);
    const [errorOverlapping, setErrorOverlapping] = useState<boolean>(false);

    const deleteCalculationBasis = (calc: CalculationBasisAPI, rv: boolean) => {
        if (!disabled) {
            const data: CalculationBasisAPI[] = []
            if (rv) {
                calculationBasisListRV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListRV(data.sort((a, b) => a.from > b.from ? -1 : 1))
            } else {
                calculationBasisListAV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListAV(data.sort((a, b) => a.from > b.from ? -1 : 1))
            }
        }
    }

    const deleteNursingTime = (calc: NursingTimeAPI) => {
        if (!disabled) {
            const data: NursingTimeAPI[] = []
            nursingTime.forEach((val) => {
                if (val.id !== calc.id) {
                    data.push(val)
                }
            })
            setNursingTime(data.sort((a, b) => a.from > b.from ? -1 : 1))
        }
    }

    const deleteNursingSupportMoney = (calc: NursingSupportMoneyAPI) => {
        if (!disabled) {
            const data: NursingSupportMoneyAPI[] = []
            nursingSupportMoneyList.forEach((val) => {
                if (val.id !== calc.id) {
                    data.push(val)
                }
            })
            setNursingSupportMoneyList(data.sort((a, b) => a.from > b.from ? -1 : 1))
        }
    }

    const editCalculationBasis = (calc: CalculationBasisAPI, rv: boolean) => {
        if (!disabled) {
            const data: CalculationBasisAPI[] = []
            if (rv) {
                calculationBasisListRV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListRV(data.sort((a, b) => a.from > b.from ? -1 : 1))
                setDateFromRV(calc.from)
                setDateUntilRV(calc.until)
                setAmountRV(calc.amount)
                setAmountFlagRV(getCalculationBasisAmountFlag().find(({ id }) => id === calc.amountFlag) || null)
            } else {
                calculationBasisListAV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListAV(data.sort((a, b) => a.from > b.from ? -1 : 1))
                setDateFromAV(calc.from)
                setDateUntilAV(calc.until)
                setAmountAV(calc.amount)
                setAmountFlagAV(getCalculationBasisAmountFlag().find(({ id }) => id === calc.amountFlag) || null)
            }
        }
    }

    const editNursingTime = (calc: NursingTimeAPI) => {
        if (!disabled) {
            const data: NursingTimeAPI[] = []
            nursingTime.forEach((val) => {
                if (val.id !== calc.id) {
                    data.push(val)
                }
            })
            setNursingTime(data.sort((a, b) => a.from > b.from ? -1 : 1))
            setDateFromNursingTime(calc.from)
            setDateUntilNursingTime(calc.until)
            setHealthInsuranceNursingTime(insuranceValues.find(({ id }) => id === calc.healthInsuranceId) || null)
            setNursingInsuranceNursingTime(insuranceValues.find(({ id }) => id === calc.nursingInsuranceId) || null)
            setAmountHealthInsuranceNursingTime(calc.amountHealthInsurance)
            setAmountNursingInsuranceNursingTime(calc.amountNursingInsurance)
            setPercentageHealthInsuranceNursingTime(calc.percentageHealthInsurance)
            setPercentageNursingInsuranceNursingTime(calc.percentageNursingInsurance)
        }
    }

    const editNursingSupportMoney = (calc: NursingSupportMoneyAPI) => {
        if (!disabled) {
            const data: NursingSupportMoneyAPI[] = []
            nursingSupportMoneyList.forEach((val) => {
                if (val.id !== calc.id) {
                    data.push(val)
                }
            })
            setNursingSupportMoneyList(data.sort((a, b) => a.from > b.from ? -1 : 1))
            setDateFromPUG(calc.from)
            setDateUntilPUG(calc.until)
            setHealthInsurancePUG(insuranceValues.find(({ id }) => id === calc.healthInsuranceId) || null)
            setAmountPUG(calc.amountPUG)
            setPercentagePUG(calc.percentagePUG)
            setAmountHealthInsurancePUG(calc.amountHealthInsurance)
            setPercentageHealthInsuranceBonusPUG(calc.percentageHealthInsurance)
            setAmountHealthInsuranceBonusPUG(calc.amountBonusHealthInsurance)
            setPercentageHealthInsuranceBonusPUG(calc.percentageBonusHealthInsurance)
            setAmountPensionInsurancePUG(calc.amountPensionInsurance)
            setPercentagePensionInsurancePUG(calc.percentagePensionInsurance)
            setAmountUnemploymentInsurancePUG(calc.amountUnemploymentInsurance)
            setPercentageUnemploymentInsurancePUG(calc.percentageUnemploymentInsurance)
        }
    }

    const onAddAddToCalculationBasisList = (rv: boolean) => {
        const dateUntil = rv ? dateUntilRV : dateUntilAV;
        const dateFrom = rv ? dateFromRV : dateFromAV;
        const amountFlag = rv ? amountFlagRV : amountFlagAV;
        const amount = rv ? amountRV : amountAV;
        setAddPushed(true)
        if (((rv && !formErrorRV) || (!rv && !formErrorAV)) && endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)) {
            const data: CalculationBasisAPI[] = [];
            if (rv) {
                calculationBasisListRV.forEach(element => {
                    data.push(element);
                })
            } else {
                calculationBasisListAV.forEach(element => {
                    data.push(element);
                })
            }
            let date;
            date = new Date(dateFrom);
            const fromFormatted = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                date.getFullYear();
            let untilFormatted;
            if (dateUntil !== null) {
                date = new Date(dateUntil);
                untilFormatted = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                    (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                    date.getFullYear();
            } else {
                untilFormatted = "Offen";
            }


            const flag = amountFlag ? amountFlag.id : "";
            const newObject: CalculationBasisAPI = {
                id: "-1",
                amount,
                amountFlag: flag,
                from: dateFrom,
                until: dateUntil,
                fromUntilString: fromFormatted + " - " + untilFormatted,
                status: "",
                client: null
            }
            data.push(newObject);
            const changedDataList = data.map(element => {
                const newObjectEffectiveDate = Date.parse(newObject.from)
                const elementEffectiveDate = Date.parse(element.from)
                if (element.until === null && newObjectEffectiveDate !== null && elementEffectiveDate !== null && newObjectEffectiveDate > elementEffectiveDate) {
                    const date = new Date(newObject.from)
                    date.setDate(date.getDate() - 1);
                    element.until = date.toDateString()
                }
                return element;
            }).sort((a, b) => a.from > b.from ? -1 : 1);
            if (!checkCalculationBasisListForInterference(changedDataList)) {
                if (rv) {
                    setCalculationBasisListRV(changedDataList);
                    setDateFromRV("");
                    setDateUntilRV("");
                    setAmountRV(0);
                    setAmountFlagRV(null);
                } else {
                    setCalculationBasisListAV(changedDataList);
                    setDateFromAV("");
                    setDateUntilAV("");
                    setAmountAV(0);
                    setAmountFlagAV(null);
                }
                setAddPushed(false);

            } else {
                setErrorOverlapping(true)
            }
        }
    }

    const onAddAddToNursingTimeList = () => {
        setAddPushed(true)
        if (!formErrorNursingTime && endingDateSameOrAfterEffectiveDate(dateUntilNursingTime, dateFromNursingTime)) {
            const data: NursingTimeAPI[] = [];
            nursingTime.forEach(element => {
                data.push(element);
            })
            const healthInsuranceId = healthInsuranceNursingTime ? healthInsuranceNursingTime.id : "-1";
            const nursingInsuranceId = nursingInsuranceNursingTime ? nursingInsuranceNursingTime.id : "-1";
            const newObject: NursingTimeAPI = {
                id: "-1",
                healthInsuranceId: healthInsuranceId,
                amountHealthInsurance: amountHealthInsuranceNursingTime,
                amountNursingInsurance: amountNursingInsuranceNursingTime,
                from: dateFromNursingTime,
                until: dateUntilNursingTime,
                nursingInsuranceId: nursingInsuranceId,
                percentageHealthInsurance: percentageHealthInsuranceNursingTime,
                percentageNursingInsurance: percentageNursingInsuranceNursingTime
            }
            data.push(newObject);
            const changedDataList = data.map(element => {
                const newObjectEffectiveDate = Date.parse(newObject.from)
                const elementEffectiveDate = Date.parse(element.from)
                if (element.until === null && newObjectEffectiveDate !== null && elementEffectiveDate !== null && newObjectEffectiveDate > elementEffectiveDate) {
                    const date = new Date(newObject.from)
                    date.setDate(date.getDate() - 1);
                    element.until = date.toDateString()
                }
                return element;
            }).sort((a, b) => a.from > b.from ? -1 : 1);
            if (!checkNursingTimeListForInterference(changedDataList)) {
                setNursingTime(changedDataList);
                setHealthInsuranceNursingTime(null)
                setNursingInsuranceNursingTime(null)
                setAmountHealthInsuranceNursingTime(0)
                setAmountNursingInsuranceNursingTime(0)
                setPercentageHealthInsuranceNursingTime(0)
                setPercentageNursingInsuranceNursingTime(0)
                setDateFromNursingTime("")
                setDateUntilNursingTime("")
                setAddPushed(false);

            } else {
                setErrorOverlapping(true)
            }
        }
    }

    const onAddAddToPUG = () => {
        setAddPushed(true)
        if (!formErrorNursingSupportMoney && endingDateSameOrAfterEffectiveDate(dateUntilPUG, dateFromPUG)) {
            const data: NursingSupportMoneyAPI[] = [];
            nursingSupportMoneyList.forEach(element => {
                data.push(element);
            })
            const healthInsuranceId = healthInsurancePUG ? healthInsurancePUG.id : "-1";
            const newObject: NursingSupportMoneyAPI = {
                id: "-1",
                healthInsuranceId: healthInsuranceId,
                amountPUG: amountPUG,
                amountBonusHealthInsurance: amountHealthInsuranceBonusPUG,
                amountHealthInsurance: amountHealthInsurancePUG,
                amountPensionInsurance: amountPensionInsurancePUG,
                amountUnemploymentInsurance: amountUnemploymentInsurancePUG,
                from: dateFromPUG,
                until: dateUntilPUG,
                percentageBonusHealthInsurance: percentageHealthInsuranceBonusPUG,
                percentageHealthInsurance: percentageHealthInsurancePUG,
                percentagePensionInsurance: percentagePensionInsurancePUG,
                percentagePUG: percentagePUG,
                percentageUnemploymentInsurance: percentageUnemploymentInsurancePUG,
            }
            data.push(newObject);
            const changedDataList = data.map(element => {
                const newObjectEffectiveDate = Date.parse(newObject.from)
                const elementEffectiveDate = Date.parse(element.from)
                if (element.until === null && newObjectEffectiveDate !== null && elementEffectiveDate !== null && newObjectEffectiveDate > elementEffectiveDate) {
                    const date = new Date(newObject.from)
                    date.setDate(date.getDate() - 1);
                    element.until = date.toDateString()
                }
                return element;
            }).sort((a, b) => a.from > b.from ? -1 : 1);
            if (!checkNursingSupportMoneyListForInterference(changedDataList, calculationBasisListRV, calculationBasisListAV, nursingTime)) {
                setNursingSupportMoneyList(changedDataList);
                setHealthInsurancePUG(null)
                setDateFromPUG("")
                setDateUntilPUG("")
                setAmountPUG(0.0)
                setPercentagePUG(0.0)
                setAmountHealthInsurancePUG(0.0)
                setPercentageHealthInsurancePUG(0.0)
                setAmountHealthInsuranceBonusPUG(0.0)
                setPercentageHealthInsuranceBonusPUG(0.0)
                setAmountPensionInsurancePUG(0.0)
                setPercentagePensionInsurancePUG(0.0)
                setAmountUnemploymentInsurancePUG(0.0)
                setPercentageUnemploymentInsurancePUG(0.0)
                setAddPushed(false);

            } else {
                setErrorOverlapping(true)
            }
        }
    }

    const checkCalculationBasisListForInterference = (calculationBasisList: CalculationBasisAPI[]) => {
        for (let i = 0; i < calculationBasisList.length; i++) {
            const dateFromI = Date.parse(calculationBasisList[i].from);
            let dateUntilI = null;
            if (calculationBasisList[i].until !== null) {
                dateUntilI = Date.parse(calculationBasisList[i].until!);

            }
            if (calculationBasisList[i].amountFlag === "AMOUNT_TYPE_YEAR") {
                if (calculationBasisList[i].until === null) {
                    return true;
                }
            }
            for (let c = i + 1; c < calculationBasisList.length; c++) {
                const dateFromC = Date.parse(calculationBasisList[c].from);
                let dateUntilC = null;
                if (calculationBasisList[c].until !== null) {
                    dateUntilC = Date.parse(calculationBasisList[c].until!);
                }
                if (calculationBasisList[i].amountFlag === calculationBasisList[c].amountFlag && datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)) {
                    return true;
                }
            }
        }
        return false;
    }

    const checkNursingTimeListForInterference = (nursingTimeList: NursingTimeAPI[]) => {
        for (let i = 0; i < nursingTimeList.length; i++) {
            const dateFromI = Date.parse(nursingTimeList[i].from);
            let dateUntilI = null;
            if (nursingTimeList[i].until !== null) {
                dateUntilI = Date.parse(nursingTimeList[i].until!);

            }
            for (let c = i + 1; c < nursingTimeList.length; c++) {
                const dateFromC = Date.parse(nursingTimeList[c].from);
                let dateUntilC = null;
                if (nursingTimeList[c].until !== null) {
                    dateUntilC = Date.parse(nursingTimeList[c].until!);
                }
                if (datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)) {
                    return true;
                }
            }
        }
        return false;
    }

    const checkNursingSupportMoneyListForInterference = (nursingSupportMoneyList: NursingSupportMoneyAPI[], calculationBasisListRV: CalculationBasisAPI[], calculationBasisListAV: CalculationBasisAPI[], nursingTimeList: NursingTimeAPI[]) => {
        for (let i = 0; i < nursingSupportMoneyList.length; i++) {
            const dateFromI = Date.parse(nursingSupportMoneyList[i].from);
            let dateUntilI = null;
            if (nursingSupportMoneyList[i].until !== null) {
                dateUntilI = Date.parse(nursingSupportMoneyList[i].until!);

            }
            for (let c = i + 1; c < nursingSupportMoneyList.length; c++) {
                const dateFromC = Date.parse(nursingSupportMoneyList[c].from);
                let dateUntilC = null;
                if (nursingSupportMoneyList[c].until !== null) {
                    dateUntilC = Date.parse(nursingSupportMoneyList[c].until!);
                }
                if (datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)) {
                    return true;
                }
            }
            for (let c = 0; c < calculationBasisListRV.length; c++) {
                const dateFromC = Date.parse(calculationBasisListRV[c].from);
                let dateUntilC = null;
                if (calculationBasisListRV[c].until !== null) {
                    dateUntilC = Date.parse(calculationBasisListRV[c].until!);
                }
                if (datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)) {
                    return true;
                }
            }
            for (let c = 0; c < calculationBasisListAV.length; c++) {
                const dateFromC = Date.parse(calculationBasisListAV[c].from);
                let dateUntilC = null;
                if (calculationBasisListAV[c].until !== null) {
                    dateUntilC = Date.parse(calculationBasisListAV[c].until!);
                }
                if (datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)) {
                    return true;
                }
            }
            for (let c = 0; c < nursingTimeList.length; c++) {
                const dateFromC = Date.parse(nursingTimeList[c].from);
                let dateUntilC = null;
                if (nursingTimeList[c].until !== null) {
                    dateUntilC = Date.parse(nursingTimeList[c].until!);
                }
                if (datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)) {
                    return true;
                }
            }

        }
        return false;
    }

    return <div className='single-view-accordion'>
        <ErrorDialog
            dialogInfo='Die Neue Berechnungsgrundlage würde sich mit bestehenden Berechnungsgrundlagen überschneiden und wird daher nicht hinzugefügt!'
            showDialog={errorOverlapping}
            onClickSubmit={() => setErrorOverlapping(false)}
        />
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsgrundlagen RV"
            startIcon={<Insights></Insights>}
            error={formErrorRV}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFrom"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFromRV}
                                    error={(dateFromRV === "" || dateFromRV < earliestEffectiveDateCaringPerson || dateFromRV < earliestEffectiveDatePersonInNeedOfCare) && addPushed}
                                    helperText={
                                        dateFromRV === "" && addPushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : (dateFromRV < earliestEffectiveDateCaringPerson || dateFromRV < earliestEffectiveDatePersonInNeedOfCare) && addPushed ? "Das Von Datum darf nicht vor dem frühsten Wirksamkeitsdatum einer der Personen liegen!" : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFromRV}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntilRV}
                                    error={(dateUntilRV === "" || !endingDateSameOrAfterEffectiveDate(dateUntilRV, dateFromRV)) && addPushed}
                                    helperText={
                                        dateUntilRV === "" && addPushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : !endingDateSameOrAfterEffectiveDate(dateUntilRV, dateFromRV) && addPushed
                                                ? "Das Bis Datum darf nicht vor dem Von Datum liegen!"
                                                : ""
                                    }
                                    disabled={disabled || dateUntilRV === null}
                                    setValueString={setDateUntilRV}
                                />
                                <FormControlLabel
                                    className="grey-border"
                                    labelPlacement="end"
                                    sx={{ marginLeft: 1, marginBottom: 2 }}
                                    control={
                                        <Checkbox
                                            checked={dateUntilRV === null}
                                            onChange={() => {
                                                if (dateUntilRV == null) {
                                                    setDateUntilRV("");
                                                } else {
                                                    setDateUntilRV(null);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={
                                        <div className="checklist-label">
                                            <div>{"Offenes Ende"}</div>
                                        </div>
                                    } />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amount"
                                    label="Betrag in €"
                                    value={amountRV}
                                    type='number'
                                    error={amountRV < 0 && addPushed}
                                    helperText={
                                        amountRV < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountRV}
                                />
                                <SelectComponent
                                    selectData={getCalculationBasisAmountFlag()}
                                    tooltip="Es wurden noch keine Betragskennzeichen angelegt."
                                    className="single-view__text-field"
                                    label="Abrechnungstyp "
                                    value={amountFlagRV?.name || ""}
                                    onChange={(value: String) => setAmountFlagRV(getCalculationBasisAmountFlag().find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={addPushed && !amountFlagRV}
                                    helperText={addPushed && !amountFlagRV ? "Es muss ein Abrechnungstyp ausgewählt werden können!" : ""}
                                ></SelectComponent>
                            </div>
                            <Button startIcon={<Add />} variant="contained" size="large" onClick={() => onAddAddToCalculationBasisList(true)}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>Berechnungsgrundlage hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="av records"
                        >
                            {calculationBasisListRV.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatCalculationBasisListEntry(value)} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Bearbeiten">
                                                    <IconButton edge="end" onClick={() => editCalculationBasis(value, true)}>
                                                        <Edit>

                                                        </Edit>
                                                    </IconButton >
                                                </Tooltip>}
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Löschen">
                                                    <IconButton edge="end" onClick={() => deleteCalculationBasis(value, true)}>
                                                        <Delete>

                                                        </Delete>
                                                    </IconButton >
                                                </Tooltip>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsgrundlagen AV"
            startIcon={<Insights></Insights>}
            error={formErrorAV}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFrom"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFromAV}
                                    error={(dateFromAV === "" || dateFromAV < earliestEffectiveDateCaringPerson || dateFromAV < earliestEffectiveDatePersonInNeedOfCare) && addPushed}
                                    helperText={
                                        dateFromAV === "" && addPushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : (dateFromAV < earliestEffectiveDateCaringPerson || dateFromAV < earliestEffectiveDatePersonInNeedOfCare) && addPushed ? "Das Von Datum darf nicht vor dem frühsten Wirksamkeitsdatum einer der Personen liegen!" : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFromAV}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntilAV}
                                    error={(dateUntilAV === "" || !endingDateSameOrAfterEffectiveDate(dateUntilAV, dateFromAV)) && addPushed}
                                    helperText={
                                        dateUntilAV === "" && addPushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : !endingDateSameOrAfterEffectiveDate(dateUntilAV, dateFromAV) && addPushed
                                                ? "Das Bis Datum darf nicht vor dem Von Datum liegen!"
                                                : ""
                                    }
                                    disabled={disabled || dateUntilAV === null}
                                    setValueString={setDateUntilAV}
                                />
                                <FormControlLabel
                                    className="grey-border"
                                    labelPlacement="end"
                                    sx={{ marginLeft: 1, marginBottom: 2 }}
                                    control={
                                        <Checkbox
                                            checked={dateUntilAV === null}
                                            onChange={() => {
                                                if (dateUntilAV == null) {
                                                    setDateUntilAV("");
                                                } else {
                                                    setDateUntilAV(null);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={
                                        <div className="checklist-label">
                                            <div>{"Offenes Ende"}</div>
                                        </div>
                                    } />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amount"
                                    label="Betrag in €"
                                    value={amountAV}
                                    type='number'
                                    error={amountAV < 0 && addPushed}
                                    helperText={
                                        amountAV < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountAV}
                                />
                                <SelectComponent
                                    selectData={getCalculationBasisAmountFlag()}
                                    tooltip="Es wurden noch keine Betragskennzeichen angelegt."
                                    className="single-view__text-field"
                                    label="Abrechnungstyp "
                                    value={amountFlagAV?.name || ""}
                                    onChange={(value: String) => setAmountFlagAV(getCalculationBasisAmountFlag().find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={addPushed && !amountFlagAV}
                                    helperText={addPushed && !amountFlagAV ? "Es muss ein Abrechnungstyp ausgewählt werden können!" : ""}
                                ></SelectComponent>
                            </div>
                            <Button startIcon={<Add />} variant="contained" size="large" onClick={() => onAddAddToCalculationBasisList(false)}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>Berechnungsgrundlage hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="av records"
                        >
                            {calculationBasisListAV.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatCalculationBasisListEntry(value)} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Bearbeiten">
                                                    <IconButton edge="end" onClick={() => editCalculationBasis(value, false)}>
                                                        <Edit>

                                                        </Edit>
                                                    </IconButton >
                                                </Tooltip>}
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Löschen">
                                                    <IconButton edge="end" onClick={() => deleteCalculationBasis(value, false)}>
                                                        <Delete>

                                                        </Delete>
                                                    </IconButton >
                                                </Tooltip>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
        <AccordionComponent
            defaultExpanded={false}
            label="Pflegezeit"
            startIcon={<Insights></Insights>}
            error={formErrorNursingTime}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFromNursingTime"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFromNursingTime}
                                    error={(dateFromNursingTime === "" || dateFromNursingTime < earliestEffectiveDateCaringPerson || dateFromNursingTime < earliestEffectiveDatePersonInNeedOfCare) && addPushed}
                                    helperText={
                                        dateFromNursingTime === "" && addPushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : (dateFromNursingTime < earliestEffectiveDateCaringPerson || dateFromNursingTime < earliestEffectiveDatePersonInNeedOfCare) && addPushed ? "Das Von Datum darf nicht vor dem frühsten Wirksamkeitsdatum einer der Personen liegen!" : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFromNursingTime}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntilNursingTime}
                                    error={(dateUntilNursingTime === "" || !endingDateSameOrAfterEffectiveDate(dateUntilNursingTime, dateFromNursingTime)) && addPushed}
                                    helperText={
                                        dateUntilNursingTime === "" && addPushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : !endingDateSameOrAfterEffectiveDate(dateUntilNursingTime, dateFromNursingTime) && addPushed
                                                ? "Das Bis Datum darf nicht vor dem Von Datum liegen!"
                                                : ""
                                    }
                                    disabled={disabled || dateUntilNursingTime === null}
                                    setValueString={setDateUntilNursingTime}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <SelectComponent
                                    selectData={insuranceValues}
                                    tooltip="Es wurden noch keine Versicherungen angelegt."
                                    className="single-view__text-field"
                                    label="Krankenversicherung"
                                    value={healthInsuranceNursingTime?.name || ""}
                                    onChange={(value: string) => setHealthInsuranceNursingTime(insuranceValues.find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={healthInsuranceNursingTime === null}
                                ></SelectComponent>
                                <SelectComponent
                                    selectData={insuranceValues}
                                    tooltip="Es wurden noch keine Versicherungen angelegt."
                                    className="single-view__text-field"
                                    label="Pflegeversicherung"
                                    value={nursingInsuranceNursingTime?.name || ""}
                                    onChange={(value: string) => setNursingInsuranceNursingTime(insuranceValues.find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={nursingInsuranceNursingTime === null}
                                ></SelectComponent>
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountHealthInsuranceNursingTime"
                                    label="Betrag Krankenversicherung in €"
                                    value={amountHealthInsuranceNursingTime}
                                    type='number'
                                    error={amountHealthInsuranceNursingTime < 0 && addPushed}
                                    helperText={
                                        amountHealthInsuranceNursingTime < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountHealthInsuranceNursingTime}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentageHealthInsuranceNursingTime"
                                    label="Betragsanteil Krankenversicherung in %"
                                    value={percentageHealthInsuranceNursingTime}
                                    type='number'
                                    error={(percentageHealthInsuranceNursingTime < 0 || percentageHealthInsuranceNursingTime > 100) && addPushed}
                                    helperText={
                                        (percentageHealthInsuranceNursingTime < 0 || percentageHealthInsuranceNursingTime > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentageHealthInsuranceNursingTime}
                                />

                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountNursingInsuranceNursingTime"
                                    label="Betrag Pflegeversicherung in €"
                                    value={amountNursingInsuranceNursingTime}
                                    type='number'
                                    error={amountNursingInsuranceNursingTime < 0 && addPushed}
                                    helperText={
                                        amountNursingInsuranceNursingTime < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountNursingInsuranceNursingTime}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentageNursingInsuranceNursingTime"
                                    label="Betragsanteil Pflegeversicherung in %"
                                    value={percentageNursingInsuranceNursingTime}
                                    type='number'
                                    error={(percentageNursingInsuranceNursingTime < 0 || percentageNursingInsuranceNursingTime > 100) && addPushed}
                                    helperText={
                                        (percentageNursingInsuranceNursingTime < 0 || percentageNursingInsuranceNursingTime > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentageNursingInsuranceNursingTime}
                                />

                            </div>
                            <Button startIcon={<Add />} variant="contained" size="large" onClick={() => onAddAddToNursingTimeList()}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>Pflegezeit hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="nursing time records"
                        >
                            {nursingTime.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatNursingTimeListEntry(value, insuranceValues)} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled &&
                                                <Tooltip title="Pflegezeit Bearbeiten">
                                                    <IconButton edge="end" onClick={() => editNursingTime(value)}>
                                                        <Edit>

                                                        </Edit>
                                                    </IconButton >
                                                </Tooltip>}
                                            {!disabled &&
                                                <Tooltip title="Pflegezeit Löschen">
                                                    <IconButton edge="end" onClick={() => deleteNursingTime(value)}>
                                                        <Delete>

                                                        </Delete>
                                                    </IconButton >
                                                </Tooltip>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
        <AccordionComponent
            defaultExpanded={false}
            label="Pflegeunterstützungsgeld "
            startIcon={<Insights></Insights>}
            error={formErrorNursingSupportMoney}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFromPUG"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFromPUG}
                                    error={(dateFromPUG === "" || dateFromPUG < earliestEffectiveDateCaringPerson || dateFromPUG < earliestEffectiveDatePersonInNeedOfCare) && addPushed}
                                    helperText={
                                        dateFromPUG === "" && addPushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : (dateFromPUG < earliestEffectiveDateCaringPerson || dateFromPUG < earliestEffectiveDatePersonInNeedOfCare) && addPushed ? "Das Von Datum darf nicht vor dem frühsten Wirksamkeitsdatum einer der Personen liegen!" : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFromPUG}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntilPUG}
                                    error={(dateUntilPUG === "" || !endingDateSameOrAfterEffectiveDate(dateUntilPUG, dateFromPUG)) && addPushed}
                                    helperText={
                                        dateUntilPUG === "" && addPushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : !endingDateSameOrAfterEffectiveDate(dateUntilPUG, dateFromPUG) && addPushed
                                                ? "Das Bis Datum darf nicht vor dem Von Datum liegen!"
                                                : ""
                                    }
                                    disabled={disabled || dateUntilPUG === null}
                                    setValueString={setDateUntilPUG}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <SelectComponent
                                    selectData={insuranceValues}
                                    tooltip="Es wurden noch keine Versicherungen angelegt."
                                    className="single-view__text-field"
                                    label="Krankenversicherung"
                                    value={healthInsurancePUG?.name || ""}
                                    onChange={(value: string) => setHealthInsurancePUG(insuranceValues.find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={healthInsurancePUG === null}
                                ></SelectComponent>
                                <div className="single-view__empty-form-width"></div>
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountPUG"
                                    label="Betrag Pflegeunterstützungsgeld in €"
                                    value={amountPUG}
                                    type='number'
                                    error={amountPUG < 0 && addPushed}
                                    helperText={
                                        amountPUG < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountPUG}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentagePUG"
                                    label="Betragsanteil Pflegeunterstützungsgeld in %"
                                    value={percentagePUG}
                                    type='number'
                                    error={(percentagePUG < 0 || percentagePUG > 100) && addPushed}
                                    helperText={
                                        (percentagePUG < 0 || percentagePUG > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentagePUG}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountPUG"
                                    label="Betrag Krankenversicherung in €"
                                    value={amountHealthInsurancePUG}
                                    type='number'
                                    error={amountHealthInsurancePUG < 0 && addPushed}
                                    helperText={
                                        amountHealthInsurancePUG < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountHealthInsurancePUG}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentagePUG"
                                    label="Betragsanteil Krankenversicherung in %"
                                    value={percentageHealthInsurancePUG}
                                    type='number'
                                    error={(percentageHealthInsurancePUG < 0 || percentageHealthInsurancePUG > 100) && addPushed}
                                    helperText={
                                        (percentageHealthInsurancePUG < 0 || percentageHealthInsurancePUG > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentageHealthInsurancePUG}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountPUG"
                                    label="Betrag Krankenversicherung Zusatzbeitrag in €"
                                    value={amountHealthInsuranceBonusPUG}
                                    type='number'
                                    error={amountHealthInsuranceBonusPUG < 0 && addPushed}
                                    helperText={
                                        amountHealthInsuranceBonusPUG < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountHealthInsuranceBonusPUG}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentagePUG"
                                    label="Betragsanteil Krankenversicherung Zusatzbeitrag in %"
                                    value={percentageHealthInsuranceBonusPUG}
                                    type='number'
                                    error={(percentageHealthInsuranceBonusPUG < 0 || percentageHealthInsuranceBonusPUG > 100) && addPushed}
                                    helperText={
                                        (percentageHealthInsuranceBonusPUG < 0 || percentageHealthInsuranceBonusPUG > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentageHealthInsuranceBonusPUG}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountPUG"
                                    label="Betrag Rentenversicherung in €"
                                    value={amountPensionInsurancePUG}
                                    type='number'
                                    error={amountPensionInsurancePUG < 0 && addPushed}
                                    helperText={
                                        amountPensionInsurancePUG < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountPensionInsurancePUG}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentagePUG"
                                    label="Betragsanteil Rentenversicherung in %"
                                    value={percentagePensionInsurancePUG}
                                    type='number'
                                    error={(percentagePensionInsurancePUG < 0 || percentagePensionInsurancePUG > 100) && addPushed}
                                    helperText={
                                        (percentagePensionInsurancePUG < 0 || percentagePensionInsurancePUG > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentagePensionInsurancePUG}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amountPUG"
                                    label="Betrag Arbeitslosenversicherung in €"
                                    value={amountUnemploymentInsurancePUG}
                                    type='number'
                                    error={amountUnemploymentInsurancePUG < 0 && addPushed}
                                    helperText={
                                        amountUnemploymentInsurancePUG < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountUnemploymentInsurancePUG}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="percentagePUG"
                                    label="Betragsanteil Arbeitslosenversicherung in %"
                                    value={percentageUnemploymentInsurancePUG}
                                    type='number'
                                    error={(percentageUnemploymentInsurancePUG < 0 || percentageUnemploymentInsurancePUG > 100) && addPushed}
                                    helperText={
                                        (percentageUnemploymentInsurancePUG < 0 || percentageUnemploymentInsurancePUG > 100) && addPushed
                                            ? "Der Prozentsatz des abzurechnende Betrag darf nur zwischen 0-100 liegen!!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setPercentageUnemploymentInsurancePUG}
                                />
                            </div>

                            <Button startIcon={<Add />} variant="contained" size="large" onClick={() => onAddAddToPUG()}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>PUG hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="nursing time records"
                        >
                            {nursingSupportMoneyList.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatNursingSupportMoneyListEntry(value, insuranceValues)} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled &&
                                                <Tooltip title="Pflegeunterstützungsgeld Bearbeiten">
                                                    <IconButton edge="end" onClick={() => editNursingSupportMoney(value)}>
                                                        <Edit>

                                                        </Edit>
                                                    </IconButton >
                                                </Tooltip>}
                                            {!disabled &&
                                                <Tooltip title="Pflegeunterstützungsgeld Löschen">
                                                    <IconButton edge="end" onClick={() => deleteNursingSupportMoney(value)}>
                                                        <Delete>

                                                        </Delete>
                                                    </IconButton >
                                                </Tooltip>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
    </div >
}
export default CalculationBasisForm;