import { InstitutionAPI, Institution } from "../models/institution";
export interface User {
    id: string;
    firstname: string;
    lastname: string;
    password: string;
    email: string;
    active: Boolean;
    permissions: string[];
    institution: Institution
}

export type UserAPI = {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    active: Boolean;
    activeString: String
    permissions: string[];
    institution: InstitutionAPI;
    institutionString: string;
    latestLogin: string;
    latestLoginString: string;
};

export type UserAPIRequest = {
    id: string;
    firstname: string;
    lastname: string;
    password: string;
    email: string;
    active: Boolean;
    permissions: string[];
    institutionID: number;
};

export type OpenTransactionsAPI = {
    objectType: string;
    id: string;
    historyNumber: string;
    effectiveDate: string;
    name: string;
    createTimestamp: string;
    createString: string;
    selected: boolean;
};

export type ProcessAPI = {
    id: string;
    user: UserAPI;
    processStatus: string;
    startTimestamp: string;
    endTimestamp: string;
    transactions: OpenTransactionsAPI[];
    userString: string;
    statusString: string;
    startString: string;
    endString: string;
    transactionText: React.ReactNode;
    transactionString: string;
    institutionString: string;
    description: string
};

export type ProcessDescriptionRequestAPI = {
    id: string;
    description: string;
};

export type UserSort = Pick<
    UserAPI,
    "firstname" | "lastname" | "email"
>;

export type ProcessSort = Pick<
    ProcessAPI,
    "userString" | "startString" | "endString" | "transactionText" | "institutionString"
>;

export interface UserRoles {
    id: string,
    name: string
}

export const getAllUserRoles = () => {
    return [
        {
            id: "USER_ROLE_SYSTEM_ADMIN",
            name: "System Admin",
        },
        {
            id: "USER_ROLE_AID_ADMIN",
            name: "Beihilfe Admin",
        },
        {
            id: "USER_ROLE_AID_USER",
            name: "Beihilfe Bearbeiter",
        },
        {
            id: "USER_ROLE_INSURANCE_ADMIN",
            name: "Versicherung Admin",
        },
        {
            id: "USER_ROLE_INSURANCE_USER",
            name: "Versicherung Bearbeiter",
        },
    ]
}

export const getSystemUserRoles = () => {
    return [
        {
            id: "USER_ROLE_SYSTEM_ADMIN",
            name: "System Admin",
        },
    ]
}

export const getAidUserRoles = () => {
    return [
        {
            id: "USER_ROLE_AID_ADMIN",
            name: "Beihilfe Admin",
        },
        {
            id: "USER_ROLE_AID_USER",
            name: "Beihilfe Bearbeiter",
        },
    ]
}

export const getInsuranceUserRoles = () => {
    return [
        {
            id: "USER_ROLE_INSURANCE_ADMIN",
            name: "Versicherung Admin",
        },
        {
            id: "USER_ROLE_INSURANCE_USER",
            name: "Versicherung Bearbeiter",
        },
    ]
}

export interface UserActive {
    id: string,
    name: string,
    realValue: boolean
}

export const getUserActiveTypes = () => {
    return [
        {
            id: "true",
            name: "Benutzerkonto aktiviert",
            realValue: true
        },
        {
            id: "false",
            name: "Benutzerkonto deaktiviert",
            realValue: false
        }
    ]
}

export const isAidUser = (user: User) => {
    if (user.institution.type === "INSTITUTION_TYPE_AID") {
        return true;
    } else {
        return false;
    }
}

export type EmailAPI = {
    email: string;
};

export type PasswordAPI = {
    password: string;
};

export type UserShortKey = keyof User;
