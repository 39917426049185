import { useEffect, useRef, useState } from 'react';
import "../details.css"
import { useNavigate } from "react-router-dom";
import CrudTable, { ColumnDefinitionType } from "../../../shared/components/crud-table/crud-table";
import { ProcessAPI, ProcessSort } from "../../../models/user";
import { cancelProcess, fetchAllProcesses, importProcess, saveProcess, takeoverProcess } from "../../../shared/requests/user.requests";
import { Sort } from "../../../hooks/useSort.hook";
import { SortingDirection } from "../../../enums/sort-by";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import AccordionComponent from "../../../shared/components/accordion-component";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";
import ErrorDialog from "../../../shared/components/standard-actions/dialogs/error-dialog";
import { clearHistory } from "../../../models/history";
import { formatProcessStatusString, formatTimestampString } from "../../../helper/formatter";

function ProcessOverview() {
    const [allProcesses, setAllProcesses] = useState<ProcessAPI[]>([]);
    const [showSaveOpenProcessDialog, setShowSaveOpenProcessDialog] = useState<boolean>(false);
    const [showImportProcessDialog, setShowImportProcessDialog] = useState<boolean>(false);
    const [showCancelOpenProcessDialog, setShowCancelOpenProcessDialog] = useState<boolean>(false);
    const [showTakeoverDialog, setShowTakeoverDialog] = useState<boolean>(false);
    const [selectedOpenProcess, setSelectedOpenProcess] = useState<ProcessAPI | null>(null);
    const [externalModifiedOpenProcesses, setExternalModifiedOpenProcesses] = useState<boolean>(false);
    const [externalModifiedNotImportedProcesses, setExternalModifiedNotImportedProcesses] = useState<boolean>(false);
    const [externalModifiedAllProcesses, setExternalModifiedAllProcesses] = useState<boolean>(false);
    const [showOpenProcessErrorDialog, setShowOpenProcessErrorDialog] = useState<boolean>(false);
    const isInitRender = useRef(true);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            clearHistory();
            fetchAllProcesses().then((response) => {
                setAllProcesses(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<ProcessAPI, keyof ProcessAPI>[] = [
        {
            key: "startString",
            header: "Start Zeitstempel",
        },
        {
            key: "transactionText",
            header: "Bearbeitete Daten",
        },
        {
            key: "description",
            header: "Notiz",
        },
    ]
    const columnsImported: ColumnDefinitionType<ProcessAPI, keyof ProcessAPI>[] = [
        {
            key: "institutionString",
            header: "Benutzer Institution",
        },
        {
            key: "userString",
            header: "Benutzer",
        },
        {
            key: "startString",
            header: "Start Zeitstempel",
        },
        {
            key: "endString",
            header: "Ende Zeitstempel",
        },
        {
            key: "transactionText",
            header: "Bearbeitete Daten",
        },
        {
            key: "description",
            header: "Notiz",
        },
    ]

    const columnsAllProcesses: ColumnDefinitionType<ProcessAPI, keyof ProcessAPI>[] = [
        {
            key: "institutionString",
            header: "Benutzer Institution",
        },
        {
            key: "userString",
            header: "Benutzer",
        },
        {
            key: "statusString",
            header: "Status",
        },
        {
            key: "startString",
            header: "Start Zeitstempel",
        },
        {
            key: "endString",
            header: "Ende Zeitstempel",
        },
        {
            key: "transactionText",
            header: "Bearbeitete Daten",
        },
        {
            key: "description",
            header: "Notiz",
        },
    ]

    const [sortBy] = useState<Sort<ProcessSort>>({
        key: "institutionString",
        direction: SortingDirection.Descending,
    });

    const openEditElementOfProccessOnClick = (element: ProcessAPI) => {
        if (element.transactions.length > 0) {
            if (element.transactions[0].objectType === "Bedürftige/r") {
                navigate(`/person-in-need-of-care/edit/` + element.transactions[0].id + "/" + element.transactions[0].historyNumber + "/" + element.id);
            } else if (element.transactions[0].objectType === "Pflegeperson") {
                navigate(`/caring-person/edit/` + element.transactions[0].id + "/" + element.transactions[0].historyNumber + "/" + element.id);
            } else if (element.transactions[0].objectType === "Berechnungsgrundlagen") {
                navigate(`/calculation-basis/edit/` + element.transactions[0].id + "/" + element.transactions[0].historyNumber + "/" + element.id);
            }
        }
    }



    const onProcessCancel = () => {
        setShowCancelOpenProcessDialog(false);
        setShowSaveOpenProcessDialog(false)
        setShowOpenProcessErrorDialog(false)
        setShowTakeoverDialog(false)
        setShowImportProcessDialog(false);
    }

    const saveProccessOnClick = (element: ProcessAPI) => {
        if (user && element.user.id === user?.id) {
            setSelectedOpenProcess(element);
            setShowSaveOpenProcessDialog(true);
        } else {
            setShowOpenProcessErrorDialog(true)
        }
    }

    const onSaveProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            saveProcess(selectedOpenProcess.id).then(() => {
                setExternalModifiedOpenProcesses(true)
                setExternalModifiedAllProcesses(true)
                setExternalModifiedNotImportedProcesses(true)
                setShowSaveOpenProcessDialog(false);
            })
        }
    }

    const importProccessOnClick = (element: ProcessAPI) => {
        setSelectedOpenProcess(element);
        setShowImportProcessDialog(true);
    }

    const onImportProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            importProcess(selectedOpenProcess.id).then(() => {
                setExternalModifiedOpenProcesses(true)
                setExternalModifiedAllProcesses(true)
                setExternalModifiedNotImportedProcesses(true)
                setShowImportProcessDialog(false);
            })
        }
    }

    const cancelProccessOnClick = (element: ProcessAPI) => {
        if (user && (element.user.id === user?.id || element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED")) {
            setSelectedOpenProcess(element);
            setShowCancelOpenProcessDialog(true);
        } else {
            setShowOpenProcessErrorDialog(true)
        }
    }

    const onCancelProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            cancelProcess(selectedOpenProcess.id).then(() => {
                setExternalModifiedOpenProcesses(true)
                setExternalModifiedAllProcesses(true)
                setExternalModifiedNotImportedProcesses(true)
                setShowCancelOpenProcessDialog(false);
            })
        }
    }

    const takeOverProcessAllowed = (element: ProcessAPI) => {
        if (user && user.permissions.includes("process:take") && element.processStatus === "E_PROCESS_STATUS_OPEN" && element.user.id !== user.id) {
            return true
        } else {
            return false;
        }
    }

    const takeOverProccessOnClick = (element: ProcessAPI) => {
        setSelectedOpenProcess(element);
        setShowTakeoverDialog(true)
    }

    const onTakeoverProcessSubmit = () => {
        if (selectedOpenProcess != null) {
            takeoverProcess(selectedOpenProcess.id).then(() => {
                setExternalModifiedOpenProcesses(true)
                setExternalModifiedAllProcesses(true)
                setExternalModifiedNotImportedProcesses(true)
                setShowTakeoverDialog(false);
            })
        }
    }

    return <PageLayout title={"Vorgänge"}>
        <ConfirmCancelDialog
            showDialog={showSaveOpenProcessDialog}
            dialogTitle="Speichern eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich abgespeichert werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onSaveProcessSubmit}
        />
        <ConfirmCancelDialog
            showDialog={showImportProcessDialog}
            dialogTitle="Importieren eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich importiert werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onImportProcessSubmit}
        />
        <ConfirmCancelDialog
            showDialog={showCancelOpenProcessDialog}
            dialogTitle="Verwerfen eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich verworfen werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onCancelProcessSubmit}
        />
        <ConfirmCancelDialog
            showDialog={showTakeoverDialog}
            dialogTitle="Übernehmen eines Vorgangs"
            dialogInfo="Soll der ausgewählte Vorgang wirklich in den eigenen Benutzer übernommen werden?"
            onClickCancel={onProcessCancel}
            onClickSubmit={onTakeoverProcessSubmit}
        />
        <ErrorDialog
            showDialog={showOpenProcessErrorDialog}
            dialogInfo="Der Vorgang kann nur von dem Benutzer, der diesen angelegt hat bearbeitet werden!"
            onClickSubmit={onProcessCancel}
        />
        <div className='single-view-accordion'>
            <AccordionComponent
                label="Eigene Offene Vorgänge"
                defaultExpanded={true}
                content={<CrudTable
                    tableData={allProcesses}
                    externalModified={externalModifiedOpenProcesses}
                    setExternalModified={setExternalModifiedOpenProcesses}
                    columns={columns}
                    sortBy={sortBy}
                    fetchElements={fetchAllProcesses}
                    callOnEditClick={openEditElementOfProccessOnClick}
                    callOnSaveClick={saveProccessOnClick}
                    callOnRestoreClick={cancelProccessOnClick}
                    dataTypeName="Vorgang"
                    dataFormatter={(element: ProcessAPI) => {
                        element.userString = element.user.lastname + ", " + element.user.firstname
                        element.statusString = formatProcessStatusString(element.processStatus)
                        element.startString = formatTimestampString(element.startTimestamp)
                        if (element.endTimestamp !== null) {
                            element.endString = formatTimestampString(element.endTimestamp)
                        } else {
                            element.endString = ""
                        }
                        element.transactionText = element.transactions.map((transaction) => {
                            return <div className="process-transaction">{transaction.objectType + ' - ' + transaction.name} </div>;
                        })
                        element.transactionString = ""
                        element.transactions.forEach((transaction) => {
                            element.transactionString += transaction.objectType + "-" + transaction.name;
                        })
                        element.institutionString = element.user.institution.name;
                        return element;
                    }}
                    filterData={(element: ProcessAPI) => {
                        if (user != null && element.user.id === user.id && element.processStatus === "E_PROCESS_STATUS_OPEN") {
                            return true;
                        }
                        return false;
                    }}
                />}
            />
        </div>

        {user && user.permissions.includes("process:import") && <div className='single-view-accordion'>
            <AccordionComponent
                label={"Importierbare Vorgänge"}
                defaultExpanded={true}
                content={<CrudTable
                    tableData={allProcesses}
                    externalModified={externalModifiedNotImportedProcesses}
                    setExternalModified={setExternalModifiedNotImportedProcesses}
                    columns={columnsImported}
                    sortBy={sortBy}
                    fetchElements={fetchAllProcesses}
                    callOnImportClick={importProccessOnClick}
                    callOnRestoreClick={cancelProccessOnClick}
                    dataTypeName="Vorgang"
                    dataFormatter={(element: ProcessAPI) => {
                        element.userString = element.user.lastname + ", " + element.user.firstname
                        element.statusString = formatProcessStatusString(element.processStatus)
                        element.startString = formatTimestampString(element.startTimestamp)
                        if (element.endTimestamp !== null) {
                            element.endString = formatTimestampString(element.endTimestamp)
                        } else {
                            element.endString = ""
                        }
                        element.transactionText = element.transactions.map((transaction) => {
                            return <div className="process-transaction">{transaction.objectType + ' - ' + transaction.name} </div>;
                        })
                        element.transactionString = ""
                        element.transactions.forEach((transaction) => {
                            element.transactionString += transaction.objectType + "-" + transaction.name;
                        })
                        element.institutionString = element.user.institution.name;
                        return element;
                    }}
                    filterData={(element: ProcessAPI) => {
                        if (user != null && element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED") {
                            return true;
                        }
                        return false;
                    }}
                />}
            />
        </div>}
        {user && user.permissions.includes("process:get") && <div className='single-view-accordion'>
            <AccordionComponent
                label={"Alle Vorgänge"}
                defaultExpanded={true}
                content={<CrudTable
                    tableData={allProcesses}
                    externalModified={externalModifiedAllProcesses}
                    setExternalModified={setExternalModifiedAllProcesses}
                    editPermission={takeOverProcessAllowed}
                    callOnTakeoverClick={takeOverProccessOnClick}
                    columns={columnsAllProcesses}
                    sortBy={sortBy}
                    fetchElements={fetchAllProcesses}
                    dataTypeName="Vorgang"
                    dataFormatter={(element: ProcessAPI) => {
                        element.userString = element.user.lastname + ", " + element.user.firstname
                        element.statusString = formatProcessStatusString(element.processStatus)
                        element.startString = formatTimestampString(element.startTimestamp)
                        if (element.endTimestamp !== null) {
                            element.endString = formatTimestampString(element.endTimestamp)
                        } else {
                            element.endString = ""
                        }
                        element.transactionText = element.transactions.map((transaction) => {
                            return <div className="process-transaction">{transaction.objectType + ' - ' + transaction.name} </div>;
                        })
                        element.transactionString = ""
                        element.transactions.forEach((transaction) => {
                            element.transactionString += transaction.objectType + "-" + transaction.name;
                        })
                        element.institutionString = element.user.institution.name;
                        return element;
                    }}
                    filterData={(element: ProcessAPI) => {
                        if (element.processStatus !== "E_PROCESS_STATUS_CANCELED") {
                            return true;
                        }
                        return false;
                    }}
                />}
            />
        </div>}
    </PageLayout>
}

export default ProcessOverview;