import {
    Toolbar,
    IconButton,
    AppBar,
    Button,
    ListItemIcon,
    Menu,
    MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toggleSideNav } from "../../redux/side-nav.reducer";
import { useDispatch } from "react-redux";
import "./header.css";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Edit, Logout } from "@mui/icons-material";
import { useState, MouseEvent, useEffect } from "react";
import { logoutRequest } from "../../redux/auth.reducer";
import { useNavigate } from "react-router-dom";
import Countdown from 'react-countdown';
import { userStillLoggedInRequest } from "../../shared/requests/user.requests";


const Header = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [date, setDate] = useState<string | number | Date>(Date.now() + (1000 * 60 * (Number(process.env.REACT_APP_INACTIVE_MINUTES_TO_LOGOFF) ? Number(process.env.REACT_APP_INACTIVE_MINUTES_TO_LOGOFF) : 10)));
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);

    useEffect(() => {
        setDate(Date.now() + (1000 * 60 * (Number(process.env.REACT_APP_INACTIVE_MINUTES_TO_LOGOFF) ? Number(process.env.REACT_APP_INACTIVE_MINUTES_TO_LOGOFF) : 10)))
        window.addEventListener('click', handleClickTimer);
        return () => window.removeEventListener("click", handleClickTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickTimer = () => {
        userStillLoggedInRequest();
        setDate(Date.now() + (1000 * 60 * (Number(process.env.REACT_APP_INACTIVE_MINUTES_TO_LOGOFF) ? Number(process.env.REACT_APP_INACTIVE_MINUTES_TO_LOGOFF) : 10)))
    }

    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch<AppDispatch>();
    const logout = () => {
        setTimeout(() => dispatch(logoutRequest()), 100);

    };
    const navigate = useNavigate();
    const editUser = () => {
        navigate(`/user/edit/` + user?.id);
    }

    return (
        <AppBar
            position="fixed"
            component="nav"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "Hex#001D53",
            }}
        >
            <Toolbar className="header__toolbar" variant="dense">
                <div className="header__button_countdown_container">
                    <IconButton
                        size="large"
                        edge="start"
                        color="secondary"
                        aria-label="menu"
                        onClick={() => dispatch(toggleSideNav())}
                        sx={{ mr: 2, color: "Hex EEEEEE" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div>Automatischer Logout in: </div>
                    <div className="header__countdown">
                        <Countdown
                            date={date}
                            autoStart
                            renderer={props => <div>{props.minutes < 10 ? "0" : ""}{props.minutes}:{props.seconds < 10 ? "0" : ""}{props.seconds}</div>}
                            onComplete={() => {
                                logout();
                            }}
                        />
                    </div>
                </div>
                <div>
                    <img
                        className="header__img-size"
                        src="/assets/img/AidPlus_Logo_white_transparent.png"
                        alt="img not found"
                    />
                </div>
                <Button
                    variant="text"
                    className="header__account-section"
                    onClick={handleClick}
                    sx={{ color: "Hex EEEEEE"}}
                    endIcon={<AccountCircleIcon color='secondary' />}
                >
                    <div className='header__account-section-current-text'>{user?.lastname}, {user?.firstname}</div>
                </Button>
            </Toolbar>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Abmelden
                </MenuItem>
                <MenuItem onClick={editUser}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    Benutzer bearbeiten
                </MenuItem>
            </Menu>
        </AppBar>
    );
}

export default Header;
