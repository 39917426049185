import { resourceAxiosInstance } from "../../config/axios.config";
import { InvalidAPIRequest } from "../../models/person";
import { PersonConnectionAPI } from "../../models/person-connection";
import { PersonInNeedOfCareAPI, PersonInNeedOfCareAPIRequest, PersonInNeedOfCareFetchAllAPI } from "../../models/person-in-need-of-care";
import { ProcessAPI } from "../../models/user";

export const fetchPersonsInNeedOfCare = (floatingAllowed: boolean) => {
    if (floatingAllowed) {
        return resourceAxiosInstance.get<PersonInNeedOfCareFetchAllAPI[]>(`/person-in-need-of-care?floating=true`, {
            cache: false,
        });
    } else {
        return resourceAxiosInstance.get<PersonInNeedOfCareFetchAllAPI[]>(`/person-in-need-of-care?floating=false`, {
            cache: false,
        });
    }
}

export const updatePersonInNeedOfCare = (personInNeedOfCare: Partial<PersonInNeedOfCareAPIRequest>) =>
    resourceAxiosInstance.patch<PersonInNeedOfCareAPI>(
        `/person-in-need-of-care/${personInNeedOfCare.id}`,
        personInNeedOfCare,
        {
            cache: false,
        }
    );


export const makePersonInNeedOfCareInvalid = (personInNeedOfCareId: string | undefined, value: InvalidAPIRequest) =>
    resourceAxiosInstance.put(`/person-in-need-of-care/${personInNeedOfCareId}`,
        value,
        {
            cache: false,
        });

export const importPersonInNeedOfCare = (personInNeedOfCareId: string | undefined, historyNumber: string) =>
    resourceAxiosInstance.put(`/person-in-need-of-care/${personInNeedOfCareId}/history/${historyNumber}/import`,
        {
            cache: false,
        });

export const effectivePersonInNeedOfCare = (personInNeedOfCareId: string | undefined, historyNumber: string) =>
    resourceAxiosInstance.put(`/person-in-need-of-care/${personInNeedOfCareId}/history/${historyNumber}/effective`,
        {
            cache: false,
        });

export const fetchPersonInNeedOfCare = (personInNeedOfCareId: string) =>
    resourceAxiosInstance.get<PersonInNeedOfCareAPI>(`/person-in-need-of-care/${personInNeedOfCareId}`, {
        cache: false,
    });

export const fetchHistoryForPersonInNeedOfCare = (personInNeedOfCareId: string) =>
    resourceAxiosInstance.get<PersonInNeedOfCareAPI[]>(`/person-in-need-of-care/${personInNeedOfCareId}/history`, {
        cache: false,
    });

export const fetchSingleHistoryForPersonInNeedOfCare = (personInNeedOfCareId: string, historyNumber: string) =>
    resourceAxiosInstance.get<PersonInNeedOfCareAPI>(`/person-in-need-of-care/${personInNeedOfCareId}/history/${historyNumber}`, {
        cache: false,
    });

export const deletePersonInNeedOfCare = (personInNeedOfCareId: string, historyNumber: string) =>
    resourceAxiosInstance.delete<PersonInNeedOfCareAPI>(`/person-in-need-of-care/${personInNeedOfCareId}/history/${historyNumber}`, {
        cache: false,
    });

export const createPersonInNeedOfCare = (personInNeedOfCare: Partial<PersonInNeedOfCareAPIRequest>) =>
    resourceAxiosInstance.post<PersonInNeedOfCareAPI>(`/person-in-need-of-care`, personInNeedOfCare, {
        cache: false,
    });

export const fetchAllPersonConnectionForPersonInNeedOfCare = (personInNeedOfCareId: string) =>
    resourceAxiosInstance.get<PersonConnectionAPI[]>(`/person-in-need-of-care/${personInNeedOfCareId}/person-connection`, {
        cache: false,
    });

export const fetchProcessAndCheckAccessabilityForPersonInNeedOfCare = (personInNeedOfCareId: string, processId: string) =>
    resourceAxiosInstance.get<ProcessAPI>(`/person-in-need-of-care/${personInNeedOfCareId}/process/${processId}`, {
        cache: false,
    });

export const fetchPersonInNeedOfCareReport = (personInNeedOfCareId: string) =>
    resourceAxiosInstance.get(`/person-in-need-of-care/${personInNeedOfCareId}/report`, {
        responseType: 'blob',
        cache: false,
    });

