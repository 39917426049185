import { useEffect } from 'react';
import { Accordion, AccordionDetails, Checkbox, Divider, FormControlLabel, FormGroup } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    UserAPIRequest,
    UserActive,
    getUserActiveTypes,
} from "../../../../models/user";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { userInformationRequest } from "../../../../redux/auth.reducer";
import { } from "../../../../redux/auth.reducer";
import SubmitButtons from "../../../../shared/components/standard-actions/submit-buttons/submit-button";
import {
    createUser,
    fetchUser,
    updateUser,
} from "../../../../shared/requests/user.requests";
import {
    fetchInstitutionsWithSystemWithoutPensionInstitutions,
} from "../../../../shared/requests/institution.requests";
import SelectComponent from "../../../../shared/components/select-component";
import { InstitutionSelect } from '../../../../models/institution';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { isValidEmail } from '../../../../helper/validation';
import { clearHistory } from '../../../../models/history';
import AutoCompleteInstitutionComponent from '../../../../shared/components/auto-complete-component-institutions';
import { Lock } from '@mui/icons-material';
import AccordionComponent from '../../../../shared/components/accordion-component';

function User() {
    const dispatch = useDispatch<AppDispatch>();
    const [institutions, setInstitutions] = useState<InstitutionSelect[]>([]);
    const [activeValues, setActiveValues] = useState<UserActive[]>([]);
    const [firstname, setFirstName] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [active, setActive] = useState<UserActive | null>({
        id: "true",
        name: "Benutzerkonto aktiviert",
        realValue: true
    });
    const [institution, setInstitution] = useState<InstitutionSelect | null>(null);
    //Permissions
    const [getDataNormal, setGetDataNormal] = useState<boolean>(false);
    const [editDataNormal, setEditDataNormal] = useState<boolean>(false);
    const [getDataEployee, setGetDataEployee] = useState<boolean>(false);
    const [editDataEployee, setEditDataEployee] = useState<boolean>(false);
    const [getDataVIP, setGetDataVIP] = useState<boolean>(false);
    const [editDataVIP, SetEditDataVIP] = useState<boolean>(false);
    const [editAllInstitutions, setEditAllInstitutions] = useState<boolean>(false);
    const [editOwnInsitutionsSettings, setEditOwnInsitutionsSettings] = useState<boolean>(false);
    const [editAidConfig, setEditAidConfig] = useState<boolean>(false);
    const [getAllUsers, setGetAllUsers] = useState<boolean>(false);
    const [editAllUsers, setEditAllUsers] = useState<boolean>(false);
    const [getOwnInstitutionUsers, setGetOwnInstitutionUsers] = useState<boolean>(false);
    const [editOwnInstitutionUsers, setEditOwnInstitutionUsers] = useState<boolean>(false);
    const [getUpdateCalculationBasis, setGetUpdateCalculationBasis] = useState<boolean>(false);
    const [startUpdateCalculationBasis, setStartUpdateCalculationBasis] = useState<boolean>(false);
    const [getCalculationRun, setGetCalculationRun] = useState<boolean>(false);
    const [startCalculationRun, setStartCalculationRun] = useState<boolean>(false);
    const [getAllProcesses, setGetAllProcesses] = useState<boolean>(false);
    const [takeProcess, setTakeProcess] = useState<boolean>(false);
    const [importProcess, setImportProcess] = useState<boolean>(false);
    const [getSystemCost, setGetSystemCost] = useState<boolean>(false);
    const [editInstitutionAnnouncements, setEditInstitutionAnnouncements] = useState<boolean>(false);
    const [editAnnouncements, setEditAnnouncements] = useState<boolean>(false);
    const [getSosipImports, setGetSosipImports] = useState<boolean>(false);
    const [startSosipImport, setStartSosipImport] = useState<boolean>(false);
    const [getAidPlusImports, setGetAidPlusImports] = useState<boolean>(false);
    const [startAidPlusImport, setStartAidPlusImport] = useState<boolean>(false);
    const [startAidPlusExport, setStartAidPlusExport] = useState<boolean>(false);
    const [getOpenPugs, setGetOpenPugs] = useState<boolean>(false);
    const [completeOpenPugs, setCompleteOpenPugs] = useState<boolean>(false);
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const { id, aidId } = useParams();
    const errorInput: boolean =
        firstname === "" || lastname === "" || email === "" || !institution?.id
        || (password === "" && !id) || (confirmPassword === "" && !id) || password !== confirmPassword
        || !isValidEmail(email);

    const navigate = useNavigate();

    const goBackToOverview = () => {
        dispatch(userInformationRequest())
        if (user && id && String(user.id) === String(id)) {
            navigate(`/home`);
        } else {
            navigate(`/user`);
        }
    }

    useEffect(() => {
        clearHistory();
        fetchInstitutionsWithSystemWithoutPensionInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                const institutionSel = {
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                }
                institutionsSelect.push(institutionSel)
                if (aidId && institutionSel.id === String(aidId)) {
                    setInstitution(institutionSel)
                }
            })
            setInstitutions(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
        setActiveValues(getUserActiveTypes());

        if (id) {
            fetchUser(id).then((response) => {
                setFirstName(response.data.firstname);
                setLastname(response.data.lastname);
                setEmail(response.data.email);
                const elementActive = getUserActiveTypes().find((element) => { return response.data.active === element.realValue; });
                if (elementActive) {
                    setActive(elementActive);
                }
                const institutionFound: InstitutionSelect | null = {
                    id: response.data.institution.id,
                    name: response.data.institution.name,
                    realValue: +response.data.institution.id,
                    type: response.data.institution.type
                }
                setInstitution(institutionFound)
                response.data.permissions.forEach(permission => {
                    if (permission === "data_normal:read") {
                        setGetDataNormal(true);
                    }
                    if (permission === "data_normal:edit") {
                        setEditDataNormal(true)
                    }
                    if (permission === "data_employee:read") {
                        setGetDataEployee(true)
                    }
                    if (permission === "data_employee:edit") {
                        setEditDataEployee(true)
                    }
                    if (permission === "data_vip:read") {
                        setGetDataVIP(true)
                    }
                    if (permission === "data_vip:edit") {
                        SetEditDataVIP(true)
                    }
                    if (permission === "institution:edit") {
                        setEditAllInstitutions(true)
                    }
                    if (permission === "institution_own:edit") {
                        setEditOwnInsitutionsSettings(true)
                    }
                    if (permission === "aid_config:edit") {
                        setEditAidConfig(true)
                    }
                    if (permission === "user:get") {
                        setGetAllUsers(true)
                    }
                    if (permission === "user:edit") {
                        setEditAllUsers(true)
                    }
                    if (permission === "user_own_institution:get") {
                        setGetOwnInstitutionUsers(true)
                    }
                    if (permission === "user_own_institution:edit") {
                        setEditOwnInstitutionUsers(true)
                    }
                    if (permission === "update_calculation_basis:get") {
                        setGetUpdateCalculationBasis(true)
                    }
                    if (permission === "update_calculation_basis:start") {
                        setStartUpdateCalculationBasis(true)
                    }
                    if (permission === "calculation:get") {
                        setGetCalculationRun(true)
                    }
                    if (permission === "calculation:start") {
                        setStartCalculationRun(true)
                    }
                    if (permission === "process:get") {
                        setGetAllProcesses(true)
                    }
                    if (permission === "process:take") {
                        setTakeProcess(true)
                    }
                    if (permission === "process:import") {
                        setImportProcess(true)
                    }
                    if (permission === "costs:get") {
                        setGetSystemCost(true)
                    }
                    if (permission === "announcements_own_institution:edit") {
                        setEditInstitutionAnnouncements(true)
                    }
                    if (permission === "announcements:edit") {
                        setEditAnnouncements(true)
                    }
                    if (permission === "sosip_import:get") {
                        setGetSosipImports(true)
                    }
                    if (permission === "sosip_import:start") {
                        setStartSosipImport(true)
                    }
                    if (permission === "aidplus_import:get") {
                        setGetAidPlusImports(true)
                    }
                    if (permission === "aidplus_export:start") {
                        setStartAidPlusExport(true)
                    }
                    if (permission === "nursing_support_money:get") {
                        setGetOpenPugs(true)
                    }
                    if (permission === "nursing_support_money:complete") {
                        setCompleteOpenPugs(true)
                    }
                })
            });
        } else if (user !== null && !user.permissions.includes("user:edit")) {
            const institutionOfUser: InstitutionSelect | null = {
                id: user.institution.id,
                name: user.institution.name,
                realValue: +user.institution.id,
                type: user.institution.type
            }
            setInstitution(institutionOfUser)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        const createUserTitle = "Benutzer erstellen";
        const editUserTitle = "Benutzer bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createUserText = "Erstellen";
        const editUserText = "Speichern";
        if (id) {
            return editUserText;
        }
        return createUserText;
    };

    const handleInputChangeInstitution = (value: InstitutionSelect | null) => {
        setInstitution(value);
    };

    const handleInputChangeActive = (value: string) => {
        setActive(activeValues.find(({ name }) => name === value) || null);
    };



    const saveUser = () => {
        const ins = institution ? institution.realValue : -1
        const realActive = active ? active.realValue : false;
        const mail = email.toLowerCase();
        const permissions: string[] = [];
        if (institution && institution.type === "INSTITUTION_TYPE_AID") {
            if (getDataNormal) {
                permissions.push("data_normal:read");
            }
            if (editDataNormal) {
                permissions.push("data_normal:edit");
            }
            if (getDataEployee) {
                permissions.push("data_employee:read");
            }
            if (editDataEployee) {
                permissions.push("data_employee:edit");
            }
            if (getDataVIP) {
                permissions.push("data_vip:read");
            }
            if (editDataVIP) {
                permissions.push("data_vip:edit");
            }
            if (editOwnInsitutionsSettings) {
                permissions.push("institution_own:edit");
            }
            if (editAidConfig) {
                permissions.push("aid_config:edit");
            }
            if (getOwnInstitutionUsers) {
                permissions.push("user_own_institution:get");
            }
            if (editOwnInstitutionUsers) {
                permissions.push("user_own_institution:edit");
            }
            if (getUpdateCalculationBasis) {
                permissions.push("update_calculation_basis:get");
            }
            if (startUpdateCalculationBasis) {
                permissions.push("update_calculation_basis:start");
            }
            if (getCalculationRun) {
                permissions.push("calculation:get");
            }
            if (startCalculationRun) {
                permissions.push("calculation:start");
            }
            if (getAllProcesses) {
                permissions.push("process:get");
            }
            if (takeProcess) {
                permissions.push("process:take");
            }
            if (importProcess) {
                permissions.push("process:import");
            }
            if (editInstitutionAnnouncements) {
                permissions.push("announcements_own_institution:edit");
            }
            if (getSosipImports) {
                permissions.push("sosip_import:get");
            }
            if (getAidPlusImports) {
                permissions.push("aidplus_import:get");
            }
            if (startAidPlusExport) {
                permissions.push("aidplus_export:start");
            }
            if (getOpenPugs) {
                permissions.push("nursing_support_money:get");
            }
            if (completeOpenPugs) {
                permissions.push("nursing_support_money:complete");
            }
        } else if (institution && institution.type === "INSTITUTION_TYPE_SYSTEM") {
            if (editAllInstitutions) {
                permissions.push("institution:edit");
            }
            if (getAllUsers) {
                permissions.push("user:get");
            }
            if (editAllUsers) {
                permissions.push("user:edit");
            }
            if (getSystemCost) {
                permissions.push("costs:get");
            }
            if (editInstitutionAnnouncements) {
                permissions.push("announcements_own_institution:edit");
            }
            if (editAnnouncements) {
                permissions.push("announcements:edit");
            }
            if (getSosipImports) {
                permissions.push("sosip_import:get");
            }
            if (startSosipImport) {
                permissions.push("sosip_import:start");
            }
            if (getAidPlusImports) {
                permissions.push("aidplus_import:get");
            }
            if (startAidPlusImport) {
                permissions.push("aidplus_import:start");
            }
        } else if (institution && (institution.type === "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE" || institution.type === "INSTITUTION_TYPE_STATUTORY_HEALTH_INSURANCE")) {
            if (getDataNormal) {
                permissions.push("data_normal:read");
            }
            if (editDataNormal) {
                permissions.push("data_normal:edit");
            }
            if (getDataEployee) {
                permissions.push("data_employee:read");
            }
            if (editDataEployee) {
                permissions.push("data_employee:edit");
            }
            if (getDataVIP) {
                permissions.push("data_vip:read");
            }
            if (editDataVIP) {
                permissions.push("data_vip:edit");
            }
            if (editOwnInsitutionsSettings) {
                permissions.push("institution_own:edit");
            }
            if (getOwnInstitutionUsers) {
                permissions.push("user_own_institution:get");
            }
            if (editOwnInstitutionUsers) {
                permissions.push("user_own_institution:edit");
            }
            if (getAllProcesses) {
                permissions.push("process:get");
            }
            if (takeProcess) {
                permissions.push("process:take");
            }
            if (editInstitutionAnnouncements) {
                permissions.push("announcements_own_institution:edit");
            }
        }
        const user: UserAPIRequest = {
            id: id ? id : "",
            firstname,
            lastname,
            password,
            email: mail,
            institutionID: ins,
            active: realActive,
            permissions
        };

        setSavePushed(true);
        if (!errorInput) {
            if (id) {
                updateUser(user).then(goBackToOverview).catch(console.error);
            } else {
                createUser(user).then(goBackToOverview).catch(console.error);
            }
        }
    };


    const getAidPermissions = () => {
        return <FormGroup>
            <div className="flex flex-column single-view__form-row-width">
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editOwnInsitutionsSettings} onChange={event => setEditOwnInsitutionsSettings(event.target.checked)} />} label="Beihilfestelle Bearbeiten" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editAidConfig} onChange={event => setEditAidConfig(event.target.checked)} />} label="Beihilfekonfiguration Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getSosipImports} onChange={event => {
                        setGetSosipImports(event.target.checked)
                    }} />} label="Sosip-Importe Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getAidPlusImports} onChange={event => {
                        setGetAidPlusImports(event.target.checked)
                    }} />} label="AidPlus-Importe Anschauen" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={startAidPlusExport} onChange={event => {
                        setStartAidPlusExport(event.target.checked)
                    }} />} label="Bewegungsdaten Exportieren" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getOwnInstitutionUsers} onChange={event => {
                        if (!event.target.checked) {
                            setEditOwnInstitutionUsers(false)
                        }
                        setGetOwnInstitutionUsers(event.target.checked);
                    }} />} label="Beihilfestellenbenutzer Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editOwnInstitutionUsers} onChange={event => {
                        if (event.target.checked) {
                            setGetOwnInstitutionUsers(true)
                        }
                        setEditOwnInstitutionUsers(event.target.checked)
                    }} />} label="Beihilfestellenbenutzer Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getUpdateCalculationBasis} onChange={event => {
                        if (!event.target.checked) {
                            setStartUpdateCalculationBasis(false)
                        }
                        setGetUpdateCalculationBasis(event.target.checked)
                    }}
                    />} label="Berechnungsgrundlagen Aktualisierungen Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={startUpdateCalculationBasis} onChange={event => {
                        if (event.target.checked) {
                            setGetUpdateCalculationBasis(true)
                        }
                        setStartUpdateCalculationBasis(event.target.checked)
                    }} />} label="Berechnungsgrundlagen Aktualisierungen Starten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getCalculationRun} onChange={event => {
                        if (!event.target.checked) {
                            setStartCalculationRun(false)
                        }
                        setGetCalculationRun(event.target.checked)
                    }} />} label="Beitragsberechnungsläufe Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={startCalculationRun} onChange={event => {
                        if (event.target.checked) {
                            setGetCalculationRun(true)
                        }
                        setStartCalculationRun(event.target.checked)
                    }} />} label="Beitragsberechnungsläufe Starten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getOpenPugs} onChange={event => {
                        if (!event.target.checked) {
                            setCompleteOpenPugs(false)
                        }
                        setGetOpenPugs(event.target.checked)
                    }} />} label="Offene Pflegeunterstützungsgelder anzeigen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={completeOpenPugs} onChange={event => {
                        if (event.target.checked) {
                            setGetOpenPugs(true)
                        }
                        setCompleteOpenPugs(event.target.checked)
                    }} />} label="Offene Pflegeunterstützungsgelder abschließen" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editInstitutionAnnouncements} onChange={event => {
                        setEditInstitutionAnnouncements(event.target.checked)
                    }} />} label="Institutions-Meldungen verwalten" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getDataNormal} onChange={event => {
                        if (!event.target.checked) {
                            setEditDataNormal(false)
                        }
                        setGetDataNormal(event.target.checked)
                    }} />} label="Normale Personen Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editDataNormal} onChange={event => {
                        if (event.target.checked) {
                            setGetDataNormal(true)
                        }
                        setEditDataNormal(event.target.checked)
                    }} />} label="Normale Personen Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getDataEployee} onChange={event => {
                        if (!event.target.checked) {
                            setEditDataEployee(false)
                        }
                        setGetDataEployee(event.target.checked)
                    }} />} label="Mitarbeiter Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editDataEployee} onChange={event => {
                        if (event.target.checked) {
                            setGetDataEployee(true)
                        }
                        setEditDataEployee(event.target.checked)
                    }} />} label="Mitarbeiter Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getDataVIP} onChange={event => {
                        if (!event.target.checked) {
                            SetEditDataVIP(false)
                        }
                        setGetDataVIP(event.target.checked)
                    }} />} label="VIPs Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editDataVIP} onChange={event => {
                        if (event.target.checked) {
                            setGetDataVIP(true)
                        }
                        SetEditDataVIP(event.target.checked)
                    }} />} label="VIPs Bearbeiten" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getAllProcesses} onChange={event => {
                        if (!event.target.checked) {
                            setTakeProcess(false)
                            setImportProcess(false)
                        }
                        setGetAllProcesses(event.target.checked)
                    }} />} label="Alle Vorgänge anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={takeProcess} onChange={event => {
                        if (event.target.checked) {
                            setGetAllProcesses(true)
                        }
                        setTakeProcess(event.target.checked)
                    }} />} label="Vorgänge übernehmen" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={importProcess} onChange={event => {
                        if (event.target.checked) {
                            setGetAllProcesses(true)
                        }
                        setImportProcess(event.target.checked)
                    }} />} label="Vorgänge von Versicherungen importieren" />
                </div>
            </div>
        </FormGroup>
    }

    const getInsurancePermissions = () => {
        return <FormGroup>
            <div className="flex flex-column single-view__form-row-width">
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editOwnInsitutionsSettings} onChange={event => setEditOwnInsitutionsSettings(event.target.checked)} />} label="Versicherung Bearbeiten" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editInstitutionAnnouncements} onChange={event => {
                        setEditInstitutionAnnouncements(event.target.checked)
                    }} />} label="Institutions-Meldungen verwalten" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getOwnInstitutionUsers} onChange={event => {
                        if (!event.target.checked) {
                            setEditOwnInstitutionUsers(false)
                        }
                        setGetOwnInstitutionUsers(event.target.checked);
                    }} />} label="Versicherungsbenutzer Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editOwnInstitutionUsers} onChange={event => {
                        if (event.target.checked) {
                            setGetOwnInstitutionUsers(true)
                        }
                        setEditOwnInstitutionUsers(event.target.checked)
                    }} />} label="Versicherungsbenutzer Bearbeiten" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getDataNormal} onChange={event => {
                        if (!event.target.checked) {
                            setEditDataNormal(false)
                        }
                        setGetDataNormal(event.target.checked)
                    }} />} label="Normale Personen Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editDataNormal} onChange={event => {
                        if (event.target.checked) {
                            setGetDataNormal(true)
                        }
                        setEditDataNormal(event.target.checked)
                    }} />} label="Normale Personen Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getDataEployee} onChange={event => {
                        if (!event.target.checked) {
                            setEditDataEployee(false)
                        }
                        setGetDataEployee(event.target.checked)
                    }} />} label="Mitarbeiter Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editDataEployee} onChange={event => {
                        if (event.target.checked) {
                            setGetDataEployee(true)
                        }
                        setEditDataEployee(event.target.checked)
                    }} />} label="Mitarbeiter Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getDataVIP} onChange={event => {
                        if (!event.target.checked) {
                            SetEditDataVIP(false)
                        }
                        setGetDataVIP(event.target.checked)
                    }} />} label="VIPs Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editDataVIP} onChange={event => {
                        if (event.target.checked) {
                            setGetDataVIP(true)
                        }
                        SetEditDataVIP(event.target.checked)
                    }} />} label="VIPs Bearbeiten" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getAllProcesses} onChange={event => {
                        if (!event.target.checked) {
                            setTakeProcess(false)
                        }
                        setGetAllProcesses(event.target.checked)
                    }} />} label="Alle Vorgänge anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={takeProcess} onChange={event => {
                        if (event.target.checked) {
                            setGetAllProcesses(true)
                        }
                        setTakeProcess(event.target.checked)
                    }} />} label="Vorgänge übernehmen" />
                </div>
            </div>
        </FormGroup>
    }

    const getSystemPermissions = () => {
        return <FormGroup>
            <div className="flex flex-column single-view__form-row-width">
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editAllInstitutions} onChange={event => setEditAllInstitutions(event.target.checked)} />} label="Institutionen Bearbeiten" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getSystemCost} onChange={event => setGetSystemCost(event.target.checked)} />} label="Systemkosten Anschauen" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getSosipImports} onChange={event => {
                        if (!event.target.checked) {
                            setStartSosipImport(false)
                        }
                        setGetSosipImports(event.target.checked)
                    }} />} label="Sosip-Importe Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={startSosipImport} onChange={event => {
                        if (event.target.checked) {
                            setGetSosipImports(true)
                        }
                        setStartSosipImport(event.target.checked)
                    }} />} label="Sosip-Import Starten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getAidPlusImports} onChange={event => {
                        if (!event.target.checked) {
                            setStartAidPlusImport(false)
                        }
                        setGetAidPlusImports(event.target.checked)
                    }} />} label="AidPlus-Importe Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={startAidPlusImport} onChange={event => {
                        if (event.target.checked) {
                            setGetAidPlusImports(true)
                        }
                        setStartAidPlusImport(event.target.checked)
                    }} />} label="AidPlus-Import Starten" />
                </div>
                <Divider></Divider>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={getAllUsers} onChange={event => {
                        if (!event.target.checked) {
                            setEditAllUsers(false)
                        }
                        setGetAllUsers(event.target.checked)
                    }} />} label="Alle Benutzer Anschauen" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editAllUsers} onChange={event => {
                        if (event.target.checked) {
                            setGetAllUsers(true)
                        }
                        setEditAllUsers(event.target.checked)
                    }} />} label="Alle Benutzer Bearbeiten" />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editAnnouncements} onChange={event => {
                        setEditAnnouncements(event.target.checked)
                    }} />} label="System-Meldungen verwalten" />
                    <FormControlLabel sx={{ width: "20%" }} control={<Checkbox checked={editInstitutionAnnouncements} onChange={event => {
                        setEditInstitutionAnnouncements(event.target.checked)
                    }} />} label="Institutions-Meldungen verwalten" />
                </div>
            </div>
        </FormGroup>
    }


    const actions = (
        <SubmitButtons
            submitText={getSavingButtonText()}
            cancelText="Abbrechen"
            onClickSubmit={saveUser}
            onClickCancel={goBackToOverview}
        />
    );

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <Accordion defaultExpanded={true}>
                <AccordionDetails sx={{ paddingTop: 2 }}>
                    <div className="single-view">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="firstname"
                                label="Vorname"
                                value={firstname}
                                type='text'
                                error={firstname === "" && savePushed}
                                helperText={
                                    firstname === "" && savePushed
                                        ? "Der Vorname darf nicht leer sein!"
                                        : ""
                                }
                                disabled={false}
                                setValueString={setFirstName}
                            />
                            <TextFieldComponent
                                required={true}
                                id="name"
                                label="Nachname"
                                value={lastname}
                                type='text'
                                error={lastname === "" && savePushed}
                                helperText={
                                    lastname === "" && savePushed
                                        ? "Der Nachname darf nicht leer sein!"
                                        : ""
                                }
                                disabled={false}
                                setValueString={setLastname}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="email"
                                label="Email-Adresse"
                                value={email}
                                type='email'
                                error={(email === "" && savePushed) || (!isValidEmail(email) && savePushed)}
                                helperText={
                                    email === "" && savePushed
                                        ? "Die Email darf nicht leer sein!"
                                        : !isValidEmail(email) && savePushed
                                            ? "Der eingegebene Wert ist keine Email Adresse!"
                                            : ""
                                }
                                disabled={false}
                                setValueString={setEmail}
                            />
                            <SelectComponent
                                selectData={activeValues}
                                tooltip="Es wurden noch keine Aktivitätswert angelegt."
                                className="single-view__text-field"
                                label="Kontostatus"
                                value={active?.name || ""}
                                onChange={handleInputChangeActive}
                                disabled={(!user?.permissions.includes("user:edit") && !user?.permissions.includes("user_own_institution:edit"))}
                                error={false}
                            ></SelectComponent>

                        </div>

                        <div className="flex flex-row single-view__form-row-width">
                            <AutoCompleteInstitutionComponent
                                noTypeGroupName='System'
                                selectData={institutions}
                                tooltip="Es wurden noch keine Institution angelegt."
                                className="single-view__text-field"
                                label="Institution"
                                value={institution}
                                onChange={handleInputChangeInstitution}
                                disabled={!user?.permissions.includes("user:edit")}
                                error={savePushed && !institution}
                            />
                            <div className="single-view__empty-form-width"></div>
                        </div>
                        <div className="flex flex-column single-view__form-row-width">
                            {id &&
                                <Divider className='info-divider' />
                            }
                            {id &&
                                <label className='info-label'>Neues Passwort wählen?</label>
                            }
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={true}
                                    id="password"
                                    label="Passwort"
                                    value={password}
                                    type='password'
                                    error={(password === "" && savePushed && !id) || (confirmPassword !== password && savePushed)}
                                    helperText={
                                        password === "" && savePushed && !id
                                            ? "Das Passwort darf nicht leer sein!"
                                            : confirmPassword !== password && savePushed ?
                                                "Die Passwörter müssen übereinstimmen!" : ""
                                    }
                                    disabled={false}
                                    setValueString={setPassword}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="confirmPassword"
                                    label="Passwort bestätigen"
                                    value={confirmPassword}
                                    type='password'
                                    error={(confirmPassword === "" && savePushed && !id) || (confirmPassword !== password && savePushed)}
                                    helperText={
                                        confirmPassword === "" && savePushed && !id
                                            ? "Das Passwort zum bestätigen darf nicht leer sein!"
                                            : confirmPassword !== password && savePushed ?
                                                "Die Passwörter müssen übereinstimmen!" : ""
                                    }
                                    disabled={false}
                                    setValueString={setConfirmPassword}
                                />
                            </div>
                        </div>
                        <AccordionComponent
                            defaultExpanded
                            label='Berechtigungen'
                            startIcon={<Lock></Lock>}
                            error={false}
                            content={
                                institution === null ? <div></div> :
                                    institution.type === "INSTITUTION_TYPE_AID" ? getAidPermissions() :
                                        institution.type === "INSTITUTION_TYPE_SYSTEM" ? getSystemPermissions() :
                                            institution.type === "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE" || institution.type === "INSTITUTION_TYPE_SYSTEM" ? getInsurancePermissions() :
                                                <div></div>
                            }
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </PageLayout>
    );
}

export default User;
