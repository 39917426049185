import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { } from "../../../../redux/auth.reducer";
import {
    fetchSingleHistoryForPersonInNeedOfCare,
} from "../../../../shared/requests/person-in-need-of-care.requests";
import { StatusSelect, ValiditySelect, getStatusValues, getValidityValues } from '../../../../models/historization';
import { InstitutionSelect } from '../../../../models/institution';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { GenderType, getGenderType } from '../../../../models/person';
import { fetchAids, fetchInsurances } from '../../../../shared/requests/institution.requests';
import PersonForm from '../../../../shared/components/person-form/person_form';
import HistoryShow from '../../../../shared/components/history-show/history-show';
import PersonInNeedOfCareForm from '../form/person-in-need-of-care-form';
import { ContractTypes, getAllContractTypes } from '../../../../models/person-in-need-of-care';
import { AidConfigAidLevels } from '../../../../models/aid';
import { fetchAidConfig } from '../../../../shared/requests/aid-request';
import { addToHistory, HistoryTimelineItemModel, updateHistoryUntilIndex } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';
import { CountrySelect, getCountries } from '../../../../models/address';
import SubmitButtonsShow from '../../../../shared/components/standard-actions/submit-buttons/submit-button-show';
import EffectiveDateForm from '../../../../shared/components/effective-date-form/effective-date-form';

function PersonInNeedOfCareHistory() {
    //Select Listen
    const [aidValues, setAidValues] = useState<InstitutionSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    const [aidLevelValues, setAidLevelValues] = useState<AidConfigAidLevels[]>([]);
    const [useSpecialConceptOfOrderPersonInNeedOfCare, setUseSpecialConceptOfOrderPersonInNeedOfCare] = useState<boolean>(false);
    const [labelConceptOfOrderOnePersonInNeedOfCare, setLabelConceptOfOrderOnePersonInNeedOfCare] = useState<string>("");
    const [labelConceptOfOrderTwoPersonInNeedOfCare, setLabelConceptOfOrderTwoPersonInNeedOfCare] = useState<string>("");
    const [labelExternalConceptOfOrderOnePersonInNeedOfCare, setLabelExternalConceptOfOrderOnePersonInNeedOfCare] = useState<string>("");
    const [labelExternalConceptOfOrderTwoPersonInNeedOfCare, setLabelExternalConceptOfOrderTwoPersonInNeedOfCare] = useState<string>("");
    const [labelSpecialConceptOfOrderOnePersonInNeedOfCare, setLabelSpecialConceptOfOrderOnePersonInNeedOfCare] = useState<string>("");
    const [labelSpecialConceptOfOrderTwoPersonInNeedOfCare, setLabelSpecialConceptOfOrderTwoPersonInNeedOfCare] = useState<string>("");
    //Bedürftige extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [conceptOfOrder1, setConceptOfOrder1] = useState<string>("");
    const [conceptOfOrder2, setConceptOfOrder2] = useState<string>("");
    const [externalConceptOfOrder1, setExternalConceptOfOrder1] = useState<string>("");
    const [externalConceptOfOrder2, setExternalConceptOfOrder2] = useState<string>("");
    const [specialConceptOfOrder1, setSpecialConceptOfOrder1] = useState<string>("");
    const [specialConceptOfOrder2, setSpecialConceptOfOrder2] = useState<string>("");
    const [aidLevel, setAidLevel] = useState<AidConfigAidLevels | null>(null);
    const [contractType, setContractType] = useState<ContractTypes | null>(null);
    const [insurance, setInsurance] = useState<InstitutionSelect | null>(null);
    const [aid, setAid] = useState<InstitutionSelect | null>(null);
    const [note, setNote] = useState<string | null>("");
    //Personendaten
    const [lastname, setLastname] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
    const [gender, setGender] = useState<GenderType | null>(null);
    const [namePrefix, setNamePrefix] = useState<string | null>("");
    const [title, setTitle] = useState<string | null>("");
    //Addressdaten
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string | null>(null);
    const [country, setCountry] = useState<CountrySelect | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //Historisierungsdaten
    const [validity, setValidity] = useState<ValiditySelect | null>(null);
    const [status, setStatus] = useState<StatusSelect | null>(null);
    const [createTimestamp, setCreateTimestamp] = useState<string>("");
    const [deleteTimestamp, setDeleteTimestamp] = useState<string>("");
    const [createTransactionID, setCreateTransactionID] = useState<string>("");
    const [createTransactionUserEmail, setCreateTransactionUserEmail] = useState<string>("");
    const [deleteTransactionID, setDeleteTransactionID] = useState<string>("");
    const [deleteTransactionUserEmail, setDeleteTransactionUserEmail] = useState<string>("");
    const [imported, setImported] = useState<boolean>(true);
    //Systemdaten
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const { id, history } = useParams();
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);

    const navigate = useNavigate();

    const goBackToOverview = () => {
        navigate(`/person-in-need-of-care`);
    }


    useEffect(() => {
        fetchAids().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setAidValues(institutionsSelect);
        })
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.id) {
                    setInsurance({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInsuranceValues(institutionsSelect);
        })
        if (id && history) {
            fetchSingleHistoryForPersonInNeedOfCare(id, history).then((response) => {
                setEffectiveDate(response.data.effectiveDate);
                setConceptOfOrder1(response.data.conceptOfOrder1);
                setConceptOfOrder2(response.data.conceptOfOrder2 ? response.data.conceptOfOrder2 : "");
                setExternalConceptOfOrder1(response.data.externalConceptOfOrder1 ? response.data.externalConceptOfOrder1 : "");
                setExternalConceptOfOrder2(response.data.externalConceptOfOrder2 ? response.data.externalConceptOfOrder2 : "");
                setSpecialConceptOfOrder1(response.data.specialConceptOfOrder1 ? response.data.specialConceptOfOrder1 : "");
                setSpecialConceptOfOrder2(response.data.specialConceptOfOrder2 ? response.data.specialConceptOfOrder2 : "");
                setNote(response.data.note)
                if (response.data.aidLevel) {
                    setAidLevel({
                        id: response.data.aidLevel.id,
                        name: String(response.data.aidLevel.percentage) + "%",
                    });
                }
                const elementContractType = getAllContractTypes(user, true).find((element) => { return response.data.contractType === element.id; });
                if (elementContractType) {
                    setContractType(elementContractType)
                }
                setInsurance({
                    id: response.data.insurance.id,
                    name: response.data.insurance.name,
                    realValue: +response.data.insurance.id,
                    type: response.data.insurance.type
                })
                setAid({
                    id: response.data.client.id,
                    name: response.data.client.name,
                    realValue: +response.data.client.id,
                    type: response.data.client.type,
                })
                // Addressdaten setzen
                if (response.data.address != null) {
                    setStreet(response.data.address.street);
                    setHouseNumber(response.data.address.houseNumber);
                    const elementCountry = getCountries().find(element => element.id === response.data.address?.country) || null
                    if (elementCountry) {
                        setCountry(elementCountry);
                    }
                    setPostalCode(response.data.address.postalCode);
                    setLocation(response.data.address.location);
                    setPOBox(response.data.address.poBox);
                    setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                    setAddressSupplement(response.data.address.addressSupplement);
                    setPhoneNumber(response.data.address.phoneNumber);
                    setFax(response.data.address.fax);
                    setEmail(response.data.address.email);
                }

                //Personendaten setzen
                setFirstname(response.data.person.firstname)
                setLastname(response.data.person.lastname)
                setDateOfBirth(response.data.person.dateOfBirth)
                setNamePrefix(response.data.person.namePrefix)
                setTitle(response.data.person.title)
                const elementGender = getGenderType().find((element) => { return response.data.person.gender === element.id; });
                if (elementGender) {
                    setGender(elementGender)
                }

                //Historiendaten setzen
                const element = getValidityValues().find((element) => { return response.data.validity === element.id; });
                if (element) {
                    setValidity(element);
                }
                const elementStatus = getStatusValues().find((element) => { return response.data.status === element.id; });
                if (elementStatus) {
                    setStatus(elementStatus);
                }
                setCreateTimestamp(response.data.createTimestamp);
                setCreateTransactionID(response.data.createTransaction.id)
                setCreateTransactionUserEmail(response.data.createTransaction.user.email)
                setImported(response.data.imported);
                if (response.data.deleteTimestamp != null) {
                    setDeleteTimestamp(response.data.deleteTimestamp);
                }
                if (response.data.deleteTransaction != null) {
                    setDeleteTransactionID(response.data.deleteTransaction.id);
                    setDeleteTransactionUserEmail(response.data.deleteTransaction.user.email)
                }
                //Beihilfestufen aus Konfiguration ziehen
                fetchAidConfig(response.data.client.id).then((response) => {
                    const aidLevelVal: AidConfigAidLevels[] = response.data.aidLevels.map((element) => {
                        return {
                            id: element.id,
                            name: String(element.percentage) + "%",
                        }
                    })
                    setAidLevelValues(aidLevelVal);
                    setUseSpecialConceptOfOrderPersonInNeedOfCare(response.data.useSpecialConceptOfOrderPersonInNeedOfCare)
                    setLabelConceptOfOrderOnePersonInNeedOfCare(response.data.labelConceptOfOrderOnePersonInNeedOfCare)
                    setLabelConceptOfOrderTwoPersonInNeedOfCare(response.data.labelConceptOfOrderTwoPersonInNeedOfCare)
                    setLabelExternalConceptOfOrderOnePersonInNeedOfCare(response.data.labelExternalConceptOfOrderOnePersonInNeedOfCare)
                    setLabelExternalConceptOfOrderTwoPersonInNeedOfCare(response.data.labelExternalConceptOfOrderTwoPersonInNeedOfCare)
                    setLabelSpecialConceptOfOrderOnePersonInNeedOfCare(response.data.labelSpecialConceptOfOrderOnePersonInNeedOfCare)
                    setLabelSpecialConceptOfOrderTwoPersonInNeedOfCare(response.data.labelSpecialConceptOfOrderTwoPersonInNeedOfCare)
                })
                if (!isCurrentRendered) {
                    const historyData: HistoryTimelineItemModel[] = [];
                    addToHistory({
                        type: "Historie",
                        name: `${response.data.person.lastname}, ${response.data.person.firstname}`,
                        url: window.location.pathname,
                        id: id
                    }).forEach((element => {
                        historyData.push({
                            type: element.type,
                            title: element.name,
                            url: element.url,
                            id: element.id
                        })
                    }))
                    setHistoryChronoItems(historyData);
                }

            });
            setCurrentRendered(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        if (!imported) {
            return "Pflegebedürftige/r Historiendetails anzeigen"
        } else {
            return "Pflegebedürftige/r importieren"
        }
    };

    const onClickBack = () => {
        if (historyChronoItems.length > 1) {
            const path: string = historyChronoItems[historyChronoItems.length - 2].url
            updateHistoryUntilIndex(historyChronoItems.length - 2)
            navigate(path)
        } else {
            goBackToOverview();
        }
    }

    const onClickEdit = () => {
        if (id) {
            navigate(`/person-in-need-of-care/edit/${id}`)
        }
    }

    const actionsShow = (
        <SubmitButtonsShow
            onClickBack={onClickBack}
            onClickEdit={onClickEdit}
        />
    );

    return (
        <PageLayout title={getUserTitle()} actions={actionsShow}>
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                    saveAndOpenPath={undefined}
                    errorInput={false}
                    setHistoryChronoItems={setHistoryChronoItems}
                />}
                <EffectiveDateForm
                    savePushed={false}
                    disabled={true}
                    effectiveDate={effectiveDate}
                    setEffectiveDate={setEffectiveDate}
                    formError={false}
                />
                <PersonForm
                    savePushed={false}
                    disabled={true}
                    lastname={lastname}
                    setLastname={setLastname}
                    firstname={firstname}
                    setFirstname={setFirstname}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    gender={gender}
                    setGender={setGender}
                    namePrefix={namePrefix}
                    setNamePrefix={setNamePrefix}
                    title={title}
                    setTitle={setTitle}
                    pensionInsuranceNumber={null}
                    setPensionInsuranceNumber={() => { }}
                    formError={false}
                />

                <AddressForm
                    savePushed={false}
                    disabled={true}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    setPOBox={setPOBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={true}
                    formError={false}
                />


                <PersonInNeedOfCareForm
                    aid={aid}
                    aidValues={aidValues}
                    aidLevelValues={aidLevelValues}
                    conceptOfOrder1={conceptOfOrder1}
                    conceptOfOrder2={conceptOfOrder2}
                    contractType={contractType}
                    disabled={true}
                    externalConceptOfOrder1={externalConceptOfOrder1}
                    externalConceptOfOrder2={externalConceptOfOrder2}
                    specialConceptOfOrder1={specialConceptOfOrder1}
                    specialConceptOfOrder2={specialConceptOfOrder2}
                    insurance={insurance}
                    insuranceValues={insuranceValues}
                    savePushed={false}
                    setAid={setAid}
                    setConceptOfOrder1={setConceptOfOrder1}
                    setConceptOfOrder2={setConceptOfOrder2}
                    setContractType={setContractType}
                    setExternalConceptOfOrder1={setExternalConceptOfOrder1}
                    setExternalConceptOfOrder2={setExternalConceptOfOrder2}
                    setSpecialConceptOfOrder1={setSpecialConceptOfOrder1}
                    setSpecialConceptOfOrder2={setSpecialConceptOfOrder2}
                    setInsurance={setInsurance}
                    setAidLevel={setAidLevel}
                    aidLevel={aidLevel}
                    note={note}
                    setNote={setNote}
                    labelConceptOfOrderOne={labelConceptOfOrderOnePersonInNeedOfCare}
                    labelConceptOfOrderTwo={labelConceptOfOrderTwoPersonInNeedOfCare}
                    labelExternalConceptOfOrderOne={labelExternalConceptOfOrderOnePersonInNeedOfCare}
                    labelExternalConceptOfOrderTwo={labelExternalConceptOfOrderTwoPersonInNeedOfCare}
                    labelSpecialConceptOfOrderOne={labelSpecialConceptOfOrderOnePersonInNeedOfCare}
                    labelSpecialConceptOfOrderTwo={labelSpecialConceptOfOrderTwoPersonInNeedOfCare}
                    useSpecialConceptOfOrder={useSpecialConceptOfOrderPersonInNeedOfCare}
                    user={user}
                    formError={false}
                />


                <HistoryShow
                    validity={validity}
                    status={status}
                    createTimestamp={createTimestamp}
                    deleteTimestamp={deleteTimestamp}
                    createTransactionID={createTransactionID}
                    createTransactionUserEmail={createTransactionUserEmail}
                    deleteTransactionID={deleteTransactionID}
                    deleteTransactionUserEmail={deleteTransactionUserEmail}
                />

            </div>
        </PageLayout>
    );
}

export default PersonInNeedOfCareHistory;
